@import "./theme-essentials.scss";  // demo level framework config override
@import "./fonts/flaticon_selection/_flaticon";  // custom font selected and downloaded from flaticon.com ! download licence for each icon !

.pass-check-bar {
    height: 8px;
    width: 100%;
    position: absolute;
    background-image: url('/theme/assets/img/layout/pass-check-bar-new.png');
    background-size:100% 100%;

    &-wrapper {
        height: 8px;
        width: 100%;
        margin-top: 5px;
        position: relative;
    }

    &-blind {
        height: 8px;
        width: 33%;
        overflow: hidden;
        position: relative;
    }
}

.kt-widget {
    &.kt-widget--user-profile-3 {
        .kt-widget__top {
            .kt-widget__content {
                .kt-widget__head {
                    .kt-widget__action {
                        display: flex
                    }
                }
            }
            .kt-widget__media {
                img {
                    width: 130px;
                    height: 130px;
                }
            }
        }
    }
    &--user-profile-1 {
        .kt-menu__nav {
            .active {
                .kt-widget__item {
                    transition: color 0.3s ease;
                    background: #f2f3f7;

                    //@include kt-rounded {
                    //    border-radius: $kt-border-radius;
                    //}

                    .kt-widget__section {
                        .kt-widget__desc {
                            color: kt-brand-color();
                        }

                        .kt-widget__icon {
                            .kt-svg-icon g [fill] {
                                fill: kt-brand-color();
                            }
                        }
                    }
                }
            }
        }
    }
}

//@include kt-mobile {
//    .kt-widget {
//        &.kt-widget--user-profile-3 {
//
//        }
//    }
//}

.complexify-password {
    &__is-ok {
        background-color: #CCFFCC !important;
        &:disabled {
            background: #dddddd !important;
        }
    }
    &__is-not-ok {
        background-color: #FFCCCC !important;
        &:disabled {
            background: #dddddd !important;
        }
    }
}
