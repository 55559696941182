.zero-section {
  position: relative;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;

  @include media-breakpoint-down(md) {

  }

  &-container { // .zero-section-container
    position: relative;
    height: 450px;
    padding: {
      top: 164px;
      bottom: 135px;
    }

    @include media-breakpoint-down(md) {
      height: 260px;
      padding-top: 64px;
    }
  }

  &-nav { // .zero-section-nav
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(50%);
    z-index: 1000;

    .nav-pills {
      // font-size: $small-font-size;
      line-height: 1;
      background-color: $white;
      border-radius: $border-radius-pill;
      // overflow: hidden;
    }

    .nav-link {
      border-radius: 0;
      padding: 1.25rem 3rem 1.2rem;
      color: $dark;

      &.active {
        background-color: $light-lighter;
        color: $primary;
      }
    }

    .nav-item {
      border-bottom: solid 1px $border-color;
      margin-bottom: -1px;

      @include media-breakpoint-down(md) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      @include media-breakpoint-up(lg) {
        &:not(:first-child) {
          border-left: solid 1px $border-color;
        }
      }
    }

    // .nav-item {
    //   &:not(:first-child) {
    //     border-left: solid 1px $border-color;
    //   }
    // }
  }

  &-nav-2 {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(65%);
    z-index: 1000;

    @include media-breakpoint-up(sm) {
      transform: translateY(75%);
    }

    @include media-breakpoint-up(lg) {
      transform: translateY(50%);
    }

    .nav-pills {
      // font-size: $small-font-size;
      line-height: 1;
      background-color: $white;
      border-radius: $border-radius-pill;
      overflow: hidden;
    }

    .nav-link {
      border-radius: 0;
      color: #222;

      &.active {
        background-color: $light-lighter;
        color: $primary;
      }
    }

    &-1 {
      .nav-link {
        padding: 1.2rem 3rem;
      }

      .nav-item {
        border-bottom: solid 1px $border-color;
        margin-bottom: -1px;

        @include media-breakpoint-down(sm) {
          flex: 0 0 100%;
          max-width: 100%;
        }

        @include media-breakpoint-up(sm) {
          &:not(:first-child) {
            border-left: solid 1px $border-color;
          }
        }

        @include media-breakpoint-between(sm, lg) {
          flex: 0 0 50%;
          max-width: 50%;

          &:nth-child(3) {
            border-left: none;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }

    &-2 {
      .nav-link {
        padding: 1.2rem 1.75rem;
      }

      .nav-item {
        &:not(:first-child) {
          border-left: solid 1px $border-color;
        }
      }

      @include media-breakpoint-down(md) {
        .nav-item {
          flex: 0 0 50%;
          max-width: 50%;

          border-bottom: solid 1px $border-color;
          margin-bottom: -1px;

          &:nth-child(3) {
            border-left: none;
          }
        }

        .nav-link.active {
          background-color: $primary;
          color: $white;
        }
      }

      @include media-breakpoint-up(lg) {
        //border-top-left-radius: 0 !important;
        //border-bottom-left-radius: 0 !important;
      }
    }
  }

  &-nav-3 {
    margin: {
      left: 1rem;
      right: 1rem;
      bottom: -2rem;
    }
    transform: translateY(-1.5rem);

    .nav-pills {
      // font-size: $small-font-size;
      line-height: 1;
      background-color: $white;
      border-radius: $border-radius-pill;
      overflow: hidden;
    }

    .nav-link {
      border-radius: 0;
      padding: 1.25rem 3rem 1.2rem;
    }

    .active .nav-link {
      background-color: $light-lighter;
      color: $primary;
    }

    .nav-item {
      border-top: solid 1px transparent;
      border-bottom: solid 1px $border-color;
      margin-bottom: -1px;
      flex: 0 0 100%;
      max-width: 100%;
    }

    *.active {
      border-top: solid 1px $border-color;
    }

    @include media-breakpoint-up(sm) {
      .nav-item {
        flex: 0 0 50%;
        max-width: 50%;

        &:not(:first-child) {
          border-left: solid 1px $border-color;
        }

        &:nth-child(3) {
          border-left: none;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      width: 834px;
      transform: translateY(-25%);

      margin: {
        left: auto;
        right: auto;
        bottom: 0;
      }

      .nav-item {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;

        &:nth-child(3) {
          border-left: solid 1px $border-color;
        }

        &:nth-child(4) {
          border-left: none;
        }
      }
    }
  }
}

.zero-section-listing {
  .zero-section-container {
    @include media-breakpoint-down(md) {
      h1 {
        display: none;
      }
    }
  }
}

.zero-section-nav,
.zero-section-menu {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  z-index: 1000;
  .nav-pills {
    line-height: 1;
    background-color: #fff;
    border-radius: 27px;
    overflow: hidden;
  }
  .nav-link {
    border-radius: 0;
    padding: 1.25rem 3rem 1.2rem;
    color: #000;
  }
  .nav-link.active {
    background-color: #fafafa;
    color: #fd521e;
  }
  .nav-item {
    border-bottom: solid 1px #e8e8e8;
    margin-bottom: -1px;
  }
  @media (max-width: 991.98px) {
    .nav-item {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  @media (min-width: 992px) {
    .nav-item:not(:first-child) {
        border-left: solid 1px #e8e8e8;
    }
  }
  
}

@media (min-width: 992px) {
	.zero-section-nav-3 {
		width: 834px;
		-webkit-transform: translateY(-25%);
		transform: translateY(-25%);
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 0;
		.nav-item {
			-ms-flex: 0 0 33.333333%;
			flex: 0 0 33.333333%;
			max-width: 33.333333%;
			&:nth-child(3) {
				border-left: solid 1px #e8e8e8;
			}
			&:nth-child(4) {
				border-left: none;
			}
		}
	}
}
@media (min-width: 576px) {
	.zero-section-nav-3 {
		.nav-item {
			-ms-flex: 0 0 50%;
			flex: 0 0 50%;
			max-width: 50%;
			&:not(:first-child) {
				border-left: solid 1px #e8e8e8;
			}
			&:nth-child(3) {
				border-left: none;
			}
		}
	}
}

.zero-section--no-bottom-spacing {
  @include media-breakpoint-down(lg) {
    padding-bottom: 0 !important;

  }
}

.zero-section-input {
  @extend .input-group;
}

.zero-section-menu {
  @extend .zero-section-nav;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .mx-2;
  @extend .mx-sm-6;
  @extend .text-lg-left;
  @include media-breakpoint-down(xs) {
    padding: 0 37px; // TODO: to check margins
  }
}
