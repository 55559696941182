///////////////////////////////
// metronic backports
//@import "./_integration/metronic-7/plugins"; // is integrated in zamiastowi theme!
//@import "./_integration/metronic-7/style"; // is integrated in zamiastowi theme!
// now load the complete CSS htmlstream Front based custom theme
@import './_integration/html-stream-theme.scss';

///////////////////////////////
// local integrations
//@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,700);
//@import "general/google-fonts";
@import "./_integration/cookie_consent";
@import './_integration/placeholder-loading.scss';

///////////////////////////////
// local customizations
//@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,700);
//@import "general/google-fonts";
@import "./general/social-icons";
@import "./general/global";
