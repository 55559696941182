
.cms-ce__bodytext {
  p {
    margin-top: 0;
    margin-bottom: 3rem;
  }
}

.promo-icons {
  svg {
    vertical-align: middle;
  }
  .bg-primary {
    svg {
      path {
        fill: #fff;
      }
    }
    .fa {
      color: #fff;
    }
  }
  .bg-white {
    svg {
      path {
        fill: $primary;
      }
    }
    .fa {
      color: $primary;
    }
  }
}

.product-display-likes {
  svg {
    vertical-align: middle;
  }
}

.slick-slide {
  card {
    &-without-image {

    }
  }
}

/* BEGIN: zero-section styling */

.zero-section {

  h1 {
    font-size: 2.8rem;

    @include media-breakpoint-down(md) {
      font-size: 2.0rem;
    }

    .claim {
      &-prefix {
        color: white;
        background-color: rgba(253, 82, 30, 0.9);
        padding: 6pt 20pt;
        &::after{
          content: "\a";
          white-space: pre;
        }
      }
      &-suffix {
        color: white;
        background-color: rgba(0, 0, 0, 0.6);
        font-size: 2.4rem;
        font-weight: normal;
        padding: 5pt 26pt;
        margin-left: 140px;
        @include media-breakpoint-down(md) {
          font-size: 1.2rem;
          margin-left: 40pt;
        }
      }
    }
  }
}

.zero-section {

  &-home {

  }

  &-section-home {

  }

  &-default {
    height: 256px;
    @include media-breakpoint-down(md) {
      height: 197px;
    }
    h1 {
      padding-top: 35pt;
      @include media-breakpoint-down(md) {
        padding-top: 20pt;
      }
    }
  }

  &-security {

  }

  &-press {
    height: 256px;
    h1 {
      padding-top: 35pt;
      margin-left: 110pt;
      @include media-breakpoint-down(md) {
        padding-top: 20pt;
        margin-left: 0;
      }
    }

    &-article {
      height: 460px;
      @include media-breakpoint-down(md) {
        height: 306px;
      }
    }

  }

  &-search {

  }

}

/* END: zero-section styling */

/*

.zero-section-container {
  &.bg-img-hero {
    h1 {
      padding-top: 35pt;
      @include media-breakpoint-down(md) {
        padding-top: 20pt;
      }
    }
  }
}

.bg-img-hero {
  h1.text-center {
    margin-left: 0;
    padding-top: 45pt;
  }
}

@media (min-width: 992px) {
  .zero-section-container {
    height: 452px;
    padding-top: 125px;
  }
}
*/

/* Container needed to position the overlay. Adjust the width as needed */
.container-overlayed {
  position: relative;

  /* Make the image to responsive */
  img {
    display: block;
    width: 100%
  }

  /* The overlay effect - lays on top of the container and over the image */
  .overlay {
    position: absolute;
    bottom: 0;
    left: auto;
    right: 0;
    background: rgba(0, 0, 0, 0.2); /* Black see-through */
    transition: .5s ease;
    opacity: 1;
    color: white;
    font-size: 9px;
    padding: 3px 5px;
    text-align: center;
  }

  /* When you mouse over the container, fade in the overlay title */
  &:hover .overlay {
    opacity: 1;
  }

}

@include media-breakpoint-up(lg) {
  .w-lg-49 {
    width: 49% !important;
    overflow: hidden
  }
  .w-lg-99 {
    width: 99% !important;
  }
}

.stick-to-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  &-full-width {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.plain-text-marker {
  border:solid 1px grey;
  margin-left: 1px !important;
  margin-top: 1px !important;
  width: 52px !important;
  height: 19px !important;
  font-weight: bold;
  background-color: rgba(255,255,255, 0.5);
  text-align: center !important;
}

/* https://www.npmjs.com/package/leaflet.fullscreen */
.fullscreen-icon {
  background-position: 0 0 !important;
  &.leaflet-fullscreen-on {
    background-position: 0 -26px !important;
  }
}