










































































































































































.favorites__handles {
  i {
    cursor: pointer;
  }
}

