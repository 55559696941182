.navbar-nav .navbar-nav-item {
  @include media-breakpoint-up(lg) {
    .navbar-expand-lg & {
      padding: {
        left: $navbar-nav-item;
        right: $navbar-nav-item;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}
.navbar-nav .nav-link {
  line-height: 1;
  letter-spacing: 1px;

  @include media-breakpoint-up(lg) {
    .navbar-expand-lg & {
      &:first-child {
        padding-left: $header-nav-link-padding-x / 2;
      }
      &:last-child {
        padding-right: $header-nav-link-padding-x / 2;
      }
    }
  }
}

/* Nav Content */
.nav-content {
  background-color: $body-bg;

  @include media-breakpoint-up(lg) {
    .nav-item {
      border-bottom: solid 1.5px #e8e8e8;

      &:not(:last-child) {
        padding-right: 0.5rem;
      }
    }
  }

  .nav-link {
    padding-bottom: 0.25rem;
    border-bottom: solid 1.5px transparent;
    margin-bottom: -1.5px;

    &.active {
      border-color: $primary;
    }
  }
}

/* Classic */
.nav-classic {
  .active > .nav-link {
    color: $nav-classic-nav-link-state-color !important;
    border-bottom-color: $nav-classic-nav-link-state-color !important;
  }
  &.nav:not(.nav-tab) .active.nav-link {
    color: $nav-classic-nav-link-color;
    border-color: $nav-classic-nav-link-border-color;
  }
}
.navi {
  &-item {

  }
  &-icon {
    text-align: center;
    margin-right: 10px;
    &.fa-stack {
      height: 1.20em;
    }
  }
}
