$grid-gutter-width: 16px;

$font-size-base: 1.0rem; // 15px

$text-lh-xs: 1;

$paragraph-margin-bottom: 1.0rem;

$small-font-size: 14px;
$font-size-2xs: 11px;
$font-size-xs: 12px;
$font-size-sm: 15px;

$font-family-base: "Atten New Regular", sans-serif;
$font-family-medium: "Atten New Medium", sans-serif;
$font-family-book: "Atten New Book", sans-serif;
$font-family-bold: "Atten New Bold", sans-serif;
$font-family-extra-bold: "Atten New ExtraBold", sans-serif;

$h1-font-size: 50px;
$h2-font-size: 40px;
$h3-font-size: 30px;
$h4-font-size: 20px;
$h5-font-size: 14px;
$h6-font-size: 12px;

$display4-size: 3.125rem;

$lead-font-size: $font-size-base * 1.125;
$lead-font-weight: 400;

$primary: #fd521e;
$secondary: #1fb7fd;
$light: #f7f7f7;
$light-lighter: #fafafa;
$dark: #000;
$dark-lighter: #696969;
$body-color: #191919;
$body-bg: #fafafa;
$tertiary: #d0d0d0;
$gainsboro: #D8D8D8;

$header-section-bg-color: $body-bg;

$facebook: #4267b2;
$google: #fff;

$text-muted: #b5b5b5;
$text-muted-darker: #575757;

$success: #76ba40;
$info: #1fb7fd;
$warning: #ffec5a;
$danger: #c72113;

$border-color: #e8e8e8;
$hr-border-color: #e8e8e8;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "light-lighter": $light-lighter,
    "dark-lighter": $dark-lighter,
    "text-muted-darker": $text-muted-darker,
    "tertiary": $tertiary,
  ),
  $theme-colors
);

$spacer: 1rem;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 2
    ),
    6: (
      $spacer * 2.5
    ),
    7: (
      $spacer * 3
    ),
    8: (
      $spacer * 3.5
    ),
    9: (
      $spacer * 4
    ),
    10: (
      $spacer * 4.5
    ),
    11: (
      $spacer * 5
    ),
    12: (
      $spacer * 5.5
    ),
    13: (
      $spacer * 6
    ),
    14: (
      $spacer * 6.5
    ),
    15: (
      $spacer * 7
    ),
    16: (
      $spacer * 7.5
    ),
    17: (
      $spacer * 8
    ),
  ),
  $spacers
);

$headings-color: $dark;
$header-nav-link-color: #696969;

$header-navbar-brand-width: 69px;
$header-navbar-brand-mobile-width: 69px;
$navbar-padding-y: 27px;
// $navbar-padding-x:                                          0;
$navbar-nav-item: 40px;

$header-nav-link-padding-y: 9.5px;
$header-nav-link-padding-x: 5px;

$font-weight-semi-medium: normal;
$font-weight-semi-bold: normal;
$font-weight-bold: normal;

$border-radius: 10px;
$border-radius-lg: 27px;
$border-radius-pill: 27px;
$border-radius-pill-lg: 50rem;

$raiting-stars: #e0e0e0;
$raiting-stars-plus: #ffec5a;

$box-shadow: 0px 0.375rem 1.5rem 0px rgba($gray-600, 0.125);
$box-shadow-sm: 0 3px 10px rgba($dark, 0.06);
$box-shadow-sm-accent: 0 3px 15px rgba($dark, 0.06);

$card-box-shadow: $box-shadow-sm;

$slick-arrow-width: 53px;
$slick-arrow-height: $slick-arrow-width;

$input-btn-line-height: 1;
$input-line-height-lg: 1;

$input-placeholder-color: #656565;
$input-focus-box-shadow: 0 0 0 0 transparent;
$input-focus-border-color: $border-color;

$input-btn-font-size: $small-font-size;
$input-btn-padding-y: $font-size-base * 0.9375;
$input-btn-padding-x: $font-size-base * 1.0625;

$input-btn-font-size-lg: $font-size-base;
$input-btn-padding-y-lg: 18px;
$input-btn-padding-x-lg: $font-size-base * 1.5;

$input-height-lg: 100%;

$btn-wide: $font-size-base * 10;

$btn-padding-y: 12.5px;
$btn-padding-x: 16px;
$btn-font-family: $font-family-medium;
$btn-font-size: $small-font-size;

$btn-padding-y-lg: 16px;
$btn-padding-x-lg: 40px;
$btn-font-size-lg: $input-btn-font-size-lg;

$btn-icon-width-xs: $font-size-base * 1.2125;

// Pagination
// =========================================================

// Width & Heights
$pagination-min-width: 85px;

// Colors
$pagination-color: $gray-700;
// $pagination-hover-color:                                    $link-color;
// $pagination-hover-bg:                                       $secondary-hover-bg-color;
// $pagination-hover-border-color:                             $secondary-hover-bg-color;

// Borders
$pagination-border-width: 0;
$pagination-page-link-border-radius: $border-radius;
// $pagination-sm-page-link-border-radius:                     $border-radius-sm;
// $pagination-lg-page-link-border-radius:                     $border-radius-lg;

// Margins
$pagination-margin-x: 0;

// Card
// =========================================================

$card-spacer-y: 1.125rem;
$card-spacer-x: 1.6875rem;
$card-header-padding-y: 1.125rem;
$card-footer-padding-y: $card-header-padding-y;

// Buttons
// =========================================================

$btn-icon-font-size-xl: 14px;
$input-btn-padding-y-xl: 20px;
$input-btn-padding-x-xl: 34px;
