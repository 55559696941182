.container {
  @include media-breakpoint-down(md) {
    padding: 0 37px;
  }
}

.teaser-column-order-rounded {
  @extend .rounded-display-lg;
  @extend .border-bottom;
  @extend .border-lg-bottom-0;
  @extend .pb-5;
  @extend .pb-lg-0;
  @extend .mb-5;
  @extend .mb-lg-11;
  overflow: hidden;
}

.white-bg {
  background-color: #fff;
}

.search-input-wrap {
  @extend .w-lg-60;
  @extend .mx-6;
  @extend .mx-lg-auto;
  @extend .pt-sm-1;
  @include media-breakpoint-down(md) {
    position: absolute;
    bottom: 105px;
    left: 0;
    right: 0;
    z-index: 1000;
  }
}

.header-hero-txt {
  @extend .h2;
  @extend .text-reset;
  @extend .text-center;
  @extend .mx-3;
  @extend .mx-sm-0;
  @extend .mb-7;
  @extend .mb-sm-9;
  @extend .visibility-hidden;
  @extend .visibility-lg-visible;
}

.container {
  @include media-breakpoint-down(xs) {
    padding: 0 37px;
  }
}

.slick-container {
  margin: 0 auto; // TODO: CHECK IF NEEDED
}

.slick-list {
  padding: 0 !important;
  overflow: visible;
}

.slick-overflow-hidden {
  .slick-list {
    overflow: hidden;
  }
  .slick-slide {
    img {
      display: block;
      width: 100%;
    }
  }
  .slick-dots {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: rem-calc(12px);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;

    li {
      margin: rem-calc(5px);

      span {
        display: block;
        width: rem-calc(10px);
        height: rem-calc(10px);
        border-radius: 50%;
        background: #fff;
        opacity: 0.7;
        cursor: pointer;
        transition: opacity 0.3s ease-in-out;
      }

      &:hover {
        span {
          opacity: 1;
        }
      }

      &.slick-active {
        span {
          opacity: 1;
        }
      }
    }

  }
}

.inside-content {
  padding: rem-calc(20px 0 0 0);
  @include media-breakpoint-up(md) {
    padding: rem-calc(0 0 0 7px);
    margin: rem-calc(-3px 0 0 0);
  }
}

.accordion-preloaded-content {
  .collapse {
    display: block;
    position: absolute;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: -1;

    &.show {
      position: static;
      visibility: visible;
      opacity: 1;
      z-index: 1;
    }
  }
}

.sort-button {
  // TODO: CHeck
  @extend .form-group;
  @extend .d-flex;
  @extend .justify-content-lg-end;
  @extend .align-items-center;
  @extend .mb-0;

  background-color: $light-lighter;
  width: 154px;
  padding: 1px 10px 1px;
  border-radius: 40px;
}

.mobile-only-label {
  display: none;
  @include media-breakpoint-down(xs) {
    display: block;
    font-size: $small-font-size;
  }
}

.js-slick-carousel-footer {
  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
    // font-size: $small-font-size;
  }
  .js-slide {
    visibility: visible;
  }
}
.js-gallery-clickable-zoom {
  margin: rem-calc(0 -5px);

  [class^="col-"], [class*=" col-"] {
    padding: rem-calc(0 5px);
  }

  .col-12 {
    order: 1;
  }
  .col-3 {
    order: 2;
    margin-top: rem-calc(10px);
  }
}

.statusy-hero {
  @include media-breakpoint-down(md) {
    padding-top: rem-calc(20px);

    .bg-img-hero {
      background: none !important;
    }
    .text-reset {
      color: $primary !important;
    }
  }
}

.statusy-location {
  position: relative;
  margin-bottom: rem-calc(15px);

  @include media-breakpoint-up(md) {
    padding: rem-calc(0 0 0 86px);
    margin-bottom: rem-calc(20px);
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: rem-calc(60px);
  }

  .text-primary {
    white-space: nowrap;
    color: $dark !important;
    font-size: rem-calc(12px);
    @include media-breakpoint-up(md) {
      color: $primary !important;
    }
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(18px);
    }
  }

  .statusy-location-road {
    @include media-breakpoint-up(lg) {
      flex-grow: 1;
    }
  }
  .statusy-location-data {
    @include media-breakpoint-up(lg) {
      padding-right: rem-calc(20px);
    }
  }

  .fa {
    color: $primary;
  }

  .d-flex {
    margin: rem-calc(0 5px 5px 0);
  }

  &__headline {
    margin: rem-calc(0 0 14px 0);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    @include media-breakpoint-up(md) {
      margin: rem-calc(0 0 8px 0);
    }
  }

  &__icon {
    width: rem-calc(30px);
    height: rem-calc(30px);
    border-radius: 50%;

    @include media-breakpoint-up(md) {
      width: rem-calc(70px);
      height: rem-calc(70px);
      position: absolute;
      left: 0;
      top: 0;
    }

  }

  &__title {
    font-size: rem-calc(15px);
    font-weight: bold;
    margin: 0;
    padding: rem-calc(5px 0 5px 10px);
    @include media-breakpoint-up(md) {
      padding: 0;
      font-size: rem-calc(24px);
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }

  &__button {
    position: absolute;
    @include media-breakpoint-up(md) {
      position: static;
    }
  }

}


/* Container needed to position an overlay with other elements over an image */
.container-overlayed {
  position: relative;

  /* Make the image to responsive */
  img {
    display: block;
    width: 100%
  }

  /* The overlay effect - lays on top of the container and over the image */
  .overlay {
    position: absolute;
    bottom: 0;
    left: auto;
    right: 0;
    background: rgba(0, 0, 0, 0.2); /* Black see-through */
    transition: .5s ease;
    opacity: 1;
    color: white;
    font-size: 9px;
    padding: 3px 5px;
    text-align: center;
  }

  /* When you mouse over the container, fade in the overlay title */
  &:hover .overlay {
    opacity: 1;
  }

}

@include media-breakpoint-up(lg) {
  .w-lg-49 {
    width: 49% !important;
    overflow: hidden
  }
  .w-lg-99 {
    width: 99% !important;
  }
}

/* position an element at the bottom edge of its parent */
.stick-to-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  &-full-width {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.subject-display-likes {
  &.text-primary {
    svg {
      path {
        fill: $primary
      }
    }
    .active {
      svg {
        path {
          fill: $secondary
        }
      }
    }
  }
}


/* Try to automatically crop center images to have the same sizes in mobile views
 https://stackoverflow.com/questions/11552380/how-to-automatically-crop-and-center-an-image
 I tried this pure img solutions but did no succeed because we sometimes have very wide images
 So the best solution so far is this background-image based with a dedicated image (standard aspect ratio) for mobile
 */
/* case for lg and higher (>= 992)*/
@include media-breakpoint-up(lg) {
  .autocrop-thumbnail {
    background-image: none !important;
  }
}
/* case below lg (< 992)*/
@include media-breakpoint-down(md) {
  .autocrop-thumbnail {
    width: auto;
    height: 213px; // optimizes for iphone 6/7/8 Plus to look like 8:5 ratio
    background-position: center center;
    background-repeat: no-repeat;
    overflow:hidden;
  }
  .autocrop-thumbnail img {
    display:none !important;
    width: 0 !important;
    height: 0 !important;
  }
}

.full-viewport-width {
  @include media-breakpoint-down(md) {
    &-mobile {
      margin-left: calc(50% - 50vw);
      margin-right:calc(50% - 50vw);
    }
  }
}

.tag {
  margin-right: 10px;
  &::before {
    content: "#";
  }
  &-large {
    font-size: 1.8rem;
    font-weight: bold;
    margin-right: 15px;
  }
}

/* begin: subscription plans*/
.subscription {
  &-label {
    font-weight: bold;
    text-transform: uppercase;

    &-free {
      @extend .text-success;
    }
    &-basic {
      @extend .text-info;
    }
    &-premium {
      @extend .text-primary;
    }
  }
}
.card-plan-info-panel {
    h5 {
      small, .small {
        font-size: 1em;
      }
    }
    .promotion-original-price {
      font-size: 0.5em !important;
      color: #908f8f;
    }
    .footer-info {
      min-height: 120px;
    }
}
/* end: subscription plans*/


.comments-list {
  margin-bottom: rem-calc(60px);
  margin-right: rem-calc(-37px);
  margin-left: rem-calc(-37px);
  @include media-breakpoint-up(lg) {
    margin-left: 0;
    margin-right: 0;
  }

  &.comments-list--sidebar {
    @include media-breakpoint-up(sm) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__headline {
    @include media-breakpoint-up(lg) {
      padding-left: rem-calc(85px);
    }
  }

  &__form {
    margin-bottom: rem-calc(-45px);
    @include media-breakpoint-up(lg) {
      margin: 0;

      .justify-content-center {
        justify-content: flex-end !important;
      }
    }
  }

  &__item {
    border-bottom: 1px solid $gainsboro;

    &:nth-child(even) {
      @extend .bg-light;
    }

    @include media-breakpoint-down(sm) {
      padding: rem-calc(25px 37px);
    }
    @include media-breakpoint-up(sm) {
      padding: rem-calc(25px 37px);
    }
    @include media-breakpoint-up(lg) {
      padding: rem-calc(35px 85px);
    }

    &:last-of-type {
      border-bottom: 0;
      @include media-breakpoint-up(sm) {
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
      }
    }

    p:last-of-type {
      margin-bottom: 0;
    }

  }

}

.block-mobile {
  @include media-breakpoint-down(sm) {
    display: block;
    width: 100% !important;
  }
}

.uslugi-buttons-list {
  @include media-breakpoint-down(sm) {
    display: block;
    width: 100% !important;

    li {
      display: block;
      width: 100%;
      margin: rem-calc(0 0 10px 0) !important;
      text-align: center;
    }

    .btn {
      display: block;
      width: 100%;
    }

  }
}

.dots-btn {
  @include media-breakpoint-down(sm) {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: rem-calc(140px);
    min-height: rem-calc(40px);
    border-radius: rem-calc(20px);
    border: 1px solid $secondary;
    color: $secondary;
  }
}

.uslugi-table {
  tr {
    td {
      &:first-child {
        width: rem-calc(150px);
        @include media-breakpoint-down(sm) {
          width: auto;
          min-width: 40%;
        }
      }
      &:nth-child(2) {
        width: rem-calc(200px);
        @include media-breakpoint-down(sm) {
          width: auto;
          padding-right: 0;
        }
      }
    }
  }


  @include media-breakpoint-down(sm) {
    display: block;
    tbody {
      display: block;
    }
    tr {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      position: relative;

      border-bottom: 1px solid $border-color;

      td {
        border: 0;
        padding-left: 0;

        &.text-right {
          width: auto !important;
          padding-right: 0;
          padding-top: rem-calc(10px) !important;
        }
      }

      .status {
        position: absolute;
        right: 0;
        top: 0;
      }

    }
  }

}

.status-filter {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

dl {
  dt {
    font-weight: bold;
  }
}

/* SVG icon */
.svg-icon {
  &-xs {
    svg {
      height: .9rem !important;
      width: .9rem !important;
    }
  }
  &-success {
    svg path[fill] {
      transition: fill 0.3s ease;
      fill: $success !important;
    }
  }
  &-primary {
    svg path[fill] {
      transition: fill 0.3s ease;
      fill: $primary !important;
    }
  }
}

.topbar-item {
  .btn-icon {
    min-width: 35px;
    height:30px;
  }
  .btn.btn-clean {
    &:hover {
      background-color: #f4f5f4 !important;
    }
  }
  &--username {
    font-size: .9rem
  }
}

.kt-notification {
  &__item {

  }
  .navi-text {
  }
  .navi-link {
    align-items: flex-start;
    align-content: flex-start;
    &.read {

    }
    &.unread {
      font-weight: bold;
    }

    .navi-icon {
      margin: 2pt 1rem auto auto !important;
    }
    .navi-text {
      margin: 0 auto !important;
    }
    .navi-label {
      margin: 2pt auto auto !important;
    }
  }
}

/* BEGIN: quick edit */
.vue-quick-edit {
  &__link--is-clickable {
    font-size: 1rem;
    padding: 0;
    color: #74788d;
    text-decoration: none;
    border-bottom: none;
  }
  &__input {
    font-family: "Atten New Book", sans-serif !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1 !important;
    color: #000 !important;
    background-color: #fff !important;
    background-clip: padding-box;
    border: 1px solid;
    border-radius: 0 !important;
    border-color: #aaa !important;
    overflow: auto !important;
    padding: 0;
  }
  &__textarea {
    font-family: "Atten New Book", sans-serif !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.6 !important;
    color: #000 !important;
    background-color: #fff !important;
    background-clip: padding-box;
    border: 1px solid;
    border-radius: 0 !important;
    border-color: #aaa !important;
    width: 100% !important;
    min-height: 100px !important;
    overflow: auto !important;
    padding: 0;
  }
}
/* END: quick edit */

.pulsate-warn {
  -webkit-animation: color_change 1s infinite alternate !important;
  -moz-animation: color_change 1s infinite alternate !important;
  -ms-animation: color_change 1s infinite alternate !important;
  -o-animation: color_change 1s infinite alternate !important;
  animation: color_change 1s infinite alternate !important;
}

@-webkit-keyframes color_change {
  from { color: inherit; }
  to { color: red; }
}
@-moz-keyframes color_change {
  from { color: inherit; }
  to { color: red; }
}
@-ms-keyframes color_change {
  from { color: inherit; }
  to { color: red; }
}
@-o-keyframes color_change {
  from { color: inherit; }
  to { color: red; }
}
@keyframes color_change {
  from { color: inherit; }
  to { color: red; }
}
