@import "../theme-essentials.scss";  // demo level framework config override

.mapbox-geocoder {

  .geocoder--input {
    position:absolute;
    z-index:1;
    width:70%;
    left:30%;
    margin-left:-15%;
    top:20px;
  }
  .mapboxgl-ctrl-geocoder { min-width:100%; }

  &--current-location-finder {
    .geocoder--map {
      width:100%;
      height:200px
    }
  }

  &--search-map {
    .geocoder--map {
      width:100%;
      height:800px
    }
  }
}


//@include kt-tablet() {
//  .mapbox-geocoder {
//    &--search-map {
//      .geocoder--map {
//        width:100%;
//        height:800px
//      }
//    }
//  }
//}
//
//@include kt-mobile() {
//  .mapbox-geocoder {
//    &--search-map {
//      .geocoder--map {
//        width:100%;
//        height:500px
//      }
//    }
//    .geocoder--input {
//      width:94%;
//      left:6%;
//      margin-left:-3%;
//      top:5px;
//    }
//  }
//}