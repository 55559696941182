.product-connect-choice {
  margin: 0;

  .product-connect-choice__item {
    display: flex;
    align-items: center;
    padding: 1rem;
    text-decoration: none;

    .product-connect-choice__item-icon {
      display:flex;
      justify-content: center;
      align-items: center;
      background-color: kt-base-color(grey, 1);
      height: 50px;
      width: 50px;
      min-width: 50px;
      border-radius: 50%;

      i {
        font-size: 1.5rem;
      }
    }

    .product-connect-choice__item-check {
      display:flex;
      height: 50px;
      width: 50px;
      min-width: 50px;
      justify-content: center;
      align-items: center;
      .kt-checkbox {
        margin-bottom: 20px;
      }
    }

    .product-connect-choice__item-wrapper {
      padding-left: 1.5rem;
      flex: 1 1 auto;

      .product-connect-choice__item-title {
        font-size: 1.1rem;
        font-weight: 500;
        color: #5d5b6f;
      }

      .product-connect-choice__item-desc {
        font-size: 1rem;
        font-weight: 300;
        color: #b3b1c2;
      }
    }

    &.disabled {
      .product-connect-choice__item-icon {
        opacity: .5;
        filter: alpha(opacity=50); /* for IE */
      }
      .product-connect-choice__item-title {
        opacity: .5;
        filter: alpha(opacity=50); /* for IE */
      }
      .product-connect-choice__item-desc {
        opacity: .5;
        filter: alpha(opacity=50); /* for IE */
      }
      .product-connect-choice__item-check {
        //color: kt-get($kt-state-colors, success, base);
        opacity: .5;
        font-size:2rem;
      }
    }

    //      @include kt-transition();

    &:hover {
      background-color: kt-base-color(grey, 1);
      border-radius: 5px;
      //        @include kt-transition();

      .product-connect-choice__item-icon  {
        background-color: kt-base-color(grey, 2);
        //          @include kt-transition();
      }
    }
  }
}
