.fc {
  .fc-toolbar-title {
    display: inline;
    color: $primary;
    font-size: 14px;
  }
}

.fc-toolbar-chunk:first-child,
.fc-toolbar-chunk:last-child {
  flex-wrap: nowrap;
  width: 50%;
}

.fc-toolbar-chunk:last-child {
  text-align: end;
}

.fc-view-type {
  width: 154px;
}

.fc-showInCalendar-button {
  padding: 0 !important;

  .hs-unfold > a {
    display: block;
    width: 239px;
    padding: 12.5px 16px;
  }
}

.fc-prev-button,
.fc-next-button {
  border-radius: $border-radius-pill;

  &:disabled {
    background-color: #F2F2F2;
    border: none;

    &:hover {
      box-shadow: none !important;
      border: none;
      opacity: 1 !important;
    }
  }
}

.fc-header-toolbar.fc-toolbar {
  .custom-select {
    color: $primary;
    border-color: $primary;
    border-radius: $border-radius-pill;
  }

  .fc-toolbar-chunk > .btn {
    color: $primary;
    border-color: $primary;
    background-color: $white;
    border-radius: $border-radius-pill;
  }
}

.fc-prevYear-button,
.fc-nextYear-button {
  color: $primary;
  font-size: 12px;
  border: none;
  background: none;

  &:hover,
  &:active,
  &:focus {
    color: rgba($primary, .8);
    background: none;
    box-shadow: none !important;
    border: none;
  }
}

.fc-prevYear-button {
  padding-right: 5px;
  margin-left: 30px;
}

.fc-nextYear-button {
  padding-left: 5px;
}

.fc-col-header-cell {
  background-color: #F5F5F5;
  border-bottom: none !important;
  border-top: none !important;

  .fc-scrollgrid-sync-inner {
    padding: 14px;
  }
}

.fc-dayGridWeek-view {
  .fc-day-sun,
  .fc-day-sat {
    color: red;
  }

  .table-bordered th.fc-day-today,
  .table-bordered td.fc-day-today {
    border-right-color: $primary !important;
  }

  .table-bordered th:first-child {
    border-top-left-radius: $border-radius-pill;
    border-bottom-left-radius: $border-radius-pill;
  }

  .table-bordered th:last-child {
    border-top-right-radius: $border-radius-pill;
    border-bottom-right-radius: $border-radius-pill;
  }

  .table-bordered td:last-child {
    border: none !important;
  }

  .table-bordered th.fc-day-today {
    color: $primary;
    background-color: #FAFAFA;
  }

  .table-bordered td.fc-day-today {
    background-color: $white !important;
  }

  .fc-daygrid-body tr .fc-day-disabled:first-child {
    background-color: #fff;
  }

  .fc-event {
    height: 80px;
    background: #fff !important;
    border-radius: 10px !important;
    border-width: 1px !important;

    .fc-event-main {
      height: 100%;
      padding: 12px;
    }
  }
}

.table-bordered {
  border: none !important;

  tr td:last-child {
    border-right: none !important;
  }
}

.fc-dayGridMonth-view {
  .fc-daygrid-day-events {
    position: absolute !important;
    top: 0;
    width: 100%;

    .float {
      position: absolute;
      left: 50%;
      top: 44px;
      transform: translateX(-50%);
    }
  }

  .table-bordered th {
    background-color: #FAFAFA;
  }

  .fc-daygrid-day-number {
    display: block;
    width: 100%;
    text-align: center;
  }

  .fc-daygrid-event {
    margin-right: 0 !important;
    margin-left: 0 !important;
    border-radius: 0;
    padding: 6.5px 0;
    text-align: center;

    &:hover {
      cursor: pointer;
    }
  }
}

.week {
  .fc-toolbar-title,
  .fc-prevYear-button,
  .fc-nextYear-button {
    display: none;
  }
}

.calendar-modal {
  .modal-dialog {
    max-width: 424px;
  }

  .modal-header {
    border-bottom: 1px solid #EDEDED;

    .close {
      position: absolute;
      top: 10px;
      right: 20px;
    }
  }

  .modal-content {
    border-radius: 10px;
  }

  .access-type {
    padding: .5rem 1.5rem;
    &.disable {
      background: transparent !important;
    }

    &.active {
      color: $white !important;
    }
  }
}

.menu-calendar-filter {
  width: 101%;
  left: -1px;
  top: -9px;
  border-radius: 22px;

  .custom-control-label {
    display: block;
  }

  .menu-calendar-filter-item {
    padding: 7.5px 21px;
  }

  .menu-calendar-filter-title,
  .menu-calendar-filter-item:not(:last-child) {
    border-bottom: 1px solid #EDEDED;
  }
}

.calendar-type-select {
  background: #fff url("../../../../../assets/img/icons/arrow-mini-primary.svg") no-repeat right 1rem center/.7rem .7rem;
}

.fc .btn:not(.fc-showInCalendar-button) {
  padding: 10.5px 16px !important;
}

.menu-calendar-filter-btn {
  padding: 10.5px 16px !important;
}

.week {
  .fc-today-button {
    width: 158px;
  }
}