
/* vue.js v-cloak */
[v-cloak] {
  display: none !important;
}

label.required:after {
  content: "\00a0*";
}

.showmore {
  cursor: pointer;
  font-weight: bold;
}

.card {
  &--disabled {
    h4, p {
      opacity: 0.4;
    }
  }
}

/* jeditable styles */
.jeditable-form {
  input {
    margin-right: 10px;
    padding: 1px;
    border: 1px solid #ccc;
    border-radius: 3px;
    width: 160px !important;
  }
  textarea {
    margin-right: 10px;
    padding: 1px;
    border: 1px solid #ccc;
    border-radius: 3px;
    width: 160px !important;
  }
}

.jeditable-form .m-btn--custom {
  padding: 2px !important;
  margin-top: 0 !important;
}

.dev-info-box {
  border:solid 1px black;
  background-color: yellow;
  padding:5px;
  color: black;
  font-size: 8pt;
  text-align: left;
}

input[readonly],
.form-control[readonly] {
  background-color: #EFEFEF;
}

.kt-scroll {
  &.ps {
    .ps__rail-y {
      width: 8px;
      .ps__thumb-y {
        width: 8px;
        border-radius: 4px;
      }
    }
  }
}

.kt-notification{
  &__item {
    .btn {
      padding: 0.35rem 0.25rem;
      margin: 0 0.35em 0 0;
      cursor: pointer !important;
      font-size: 0.8rem;
    }
  }
}

.kt-portlet {
  .kt-portlet__head {
    &.kt-portlet__head--xl {
      min-height: 100px
    }
  }
  &--tabs.kt-portlet--toolbar {
    .kt-portlet__head {
      .kt-portlet__head-label {
        align-items: normal;
      }
      .kt-portlet__head-toolbar {
        align-items: center;
      }
    }
  }
}

/* general tuning of .kt-widget */
.kt-widget {
  &.kt-widget--user-profile-1 {
    .kt-widget__head {
      .kt-widget__media {
        img {
          width: 90px;
          height: 90px;
        }
      }
    }
  }
}

.kt-header__topbar {
  .kt-header__topbar-item {
    &.kt-header__topbar-item--langs {
      margin-right: 0.25rem;
    }
  }
}

.kt-sections-header {
  background-color: rgba(255,255,255,1.0);
  display: flex;
  align-items: stretch;
  padding: 0;
  justify-content: space-between;
  flex-wrap: wrap;

  &__link {
    display: flex;
    align-items: center;
    padding: 15px 0;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    justify-content: center;
    color: #666;
    font-size: 1.0em;
    font-weight: bold;

    &--title {
      margin-left: 4px;
    }
  }

  .section-accommodation {
    background-color: #EFEFEF;
    border-right: solid 1px #FFF;
    &.active {
      background-color: #00ACED;
      color: #FFF;
    }
  }

  .section-gastronomy {
    background-color: #EFEFEF;
    border-right: solid 1px #FFF;
    &.active {
      background-color: #00ACED;
      color: #FFF;
    }
  }

  .section-farm-food {
    background-color: #EFEFEF;
    border-right: solid 1px #FFF;
    &.active {
      background-color: #00ACED;
      color: #FFF;
    }
  }

  .section-handicraft {
    background-color: #EFEFEF;
    &.active {
      background-color: #00ACED;
      color: #FFF;
    }
  }

}

//@include kt-mobile() {
//    .kt-sections-header__link--title {
//        display: none
//    }
//}

.preview-label {
  position: fixed;
  z-index: 99999;
  width: 100%;
  top: 0;
  div {
    background-color: red;
    font-weight:normal;
    font-size: 12px;
    color: white;
    text-align: center;
    padding: 1px;
    margin-left: 50px;
    margin-right: 50px;
  }
  a {
    color: #FFCC00;
    font-weight: bold;
  }
}

.disabled.disabled-clickable {
  pointer-events: auto !important;
}

.modal-backdrop {
  opacity: 0.5;
}

.kt-content > .kt-container {
  margin-top:20pt;
}

//@include kt-mobile() {
//    .app-body-home {
//        .kt-widget19 {
//            &__wrapper {
//                display: none;
//            }
//            &__pic {
//                display: none;
//            }
//            &__text {
//                display: none;
//            }
//            &__action {
//                padding: 0;
//                margin: 0;
//                .btn {
//                    width: 100%;
//                }
//            }
//            &.kt-portlet {
//                background-color: transparent;
//                box-shadow: none;
//            }
//            .kt-portlet {
//                &__body {
//                    padding: 0;
//                    margin: 0;
//                }
//            }
//        }
//    }
//}

.btn {
  &-grey {
    background-color: #464545;
    border-color: #000000;
    color: #ffffff;
  }
  &-inactive {
    opacity: 0.4 !important;
    &:hover {
      cursor: default !important;
      opacity: 0.4;
    }
  }
  &.btn-availability-status-free {
    color: #ffffff;
    background-color: #76BA40;
    border-color: #76BA40;
    &:hover {
      color: #000;
    }
  }
  &.btn-availability-status-reserved {
    color: #ffffff;
    background-color: #FD521E;
    border-color: #fd521e;
    &:hover {
      color: #000;
    }
  }
  &.btn-availability-status-occupied {
    color: #ffffff;
    background-color: #C72113;
    border-color: #C72113;
    &:hover {
      color: #000;
    }
  }
  &.btn-availability-status-not-available {
    color: #ffffff;
    background-color: #666;
    border-color: #666;
    &:hover {
      color: #000;
    }
  }
}

.form-group {
  // position the form-group element to make it a positioned ancestor for
  // the .invalid-feedback element to position this correctly
  position: relative !important
}
/* override this from default metronic, which hides the message by default, we rely on parsley */
.form-group .invalid-feedback {
  display: block
}

.a2lix_translationsLocales {
  margin-bottom: 15px;
  .nav-link {
    padding: 0.5rem 0;
  }
}

fieldset {
  &.section {
    legend {
      font-family: "Atten New Bold", sans-serif;
      font-weight: normal !important;
      color: #000;
      font-size: 1.875rem;
      margin-top: 0;
      margin-bottom: 0.5rem;
      line-height: 1.4;
    }
  }
  &.sub-section {
    legend {
      color: $primary !important;
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
      font-weight: normal;
      line-height: 1.4;
      margin-top: 0;
      font-family: "Atten New Regular", sans-serif;
    }
  }
}

.subject-display-likes {
  &.text-primary {
    svg {
      path {
        fill: $primary
      }
    }
    .active {
      svg {
        path {
          fill: $secondary
        }
      }
    }
  }
}

.grecaptcha-badge { visibility: hidden; }

.js-slick-carousel {
  &-wrapper {
    min-height: 300px;
    margin-left: -200px;
    margin-right: -200px;
    padding-left: 200px;
    padding-right: 200px;
    overflow: hidden;
  }
}

.menu-placeholder-claim {
  font-size: 25pt;
  font-weight: bold;
}

.gallery-edit {
  &__body {
    .card {
      margin-right: 10px;
      margin-bottom: 10px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
