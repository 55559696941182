.m-dropzone {
  &__msg-title {
    font-size: 1.2rem;
    line-height: 1.4;
    font-weight: normal;
    color: #b5b5b5 !important;
  }
}
.dropzone {
  border: 1px solid $secondary;
  border-radius: 10px;
}