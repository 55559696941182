














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.fullscreen-icon {
  background-position: 0 26px;
}

#poi-location-manage {
  #map-canvas {
    height: 1124px;
  }
  &:fullscreen {
    #map-canvas {
      height: 100% !important;
    }
  }
}
