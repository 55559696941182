//
// Main Style
//


////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 1: Initialize
@import "init";

// 2: Bootstrap framework includes
// !! DO NOT INCLUDE entire bootstrao to not override htmlstream theme !!!
// !! only variables, function and mixins !!
// commented out here because thos things are imported in our main theme file scss/theme.scss
//@import "bootstrap/scss/functions.scss";
//@import "bootstrap/scss/variables.scss";
//@import "bootstrap/scss/mixins.scss";

// scope ALL custom metronic integration under .metronic master class
// to avoid overwriting ANY of htmlstream styles !!!
.metronic-scope {

  // 3: Components
  @import "components/utilities";
  @import "components/burger-icon";
  @import "components/card";
  @import "components/wizard";
  //@import "components/type";
  //@import "components/breadcrumb";
  @import "components/nav";
}
  @import "components/alert";
.metronic-scope {
  @import "components/buttons";
  //@import "components/code";
}
  @import "components/dropdown";
.metronic-scope {
  //@import "components/forms";
  //@import "components/grid";
  @import "components/modal";
  //@import "components/progress";
  //@import "components/table";
  //@import "components/popover";
  //@import "components/tooltip";
  //@import "components/accordion";
  //@import "components/datatable";
  @import "components/checkbox";
  //@import "components/radio";
  //@import "components/switch";
  //@import "components/option";
  //@import "components/dialog";
  //@import "components/strikethrough";
  @import "components/separator";
  @import "components/image-input";
  @import "components/label";
  //@import "components/loader";
}
  @import "components/symbol";
  @import "components/pulse";
.metronic-scope {
  //@import "components/page-loader";
  //@import "components/quick-search";
  //@import "components/scrolltop";
}
  @import "components/spinner";
.metronic-scope {
  //@import "components/sticky-toolbar";
  @import "components/svg-icon";
  @import "components/svg";
  //@import "components/timeline";
  //@import "components/timeline-2";
  //@import "components/timeline-3";
  //@import "components/timeline-4";
  //@import "components/timeline-5";
  //@import "components/timeline-6";
  @import "components/overlay";
  //@import "components/wave";
  //@import "components/list";
  //@import "components/bullet";
  @import "components/navi";
  //@import "components/ribbon";
  //@import "components/offcanvas";
  //@import "components/toggle";

}

// scope ALL custom metronic integration under .metronic master class
// to avoid overwriting ANY of htmlstream styles !!!
.metronic-scope {

  // 4: Plugins
  //@import "vendors/plugins/formvalidation";
}
  @import "vendors/plugins/blockui";
.metronic-scope {
  //@import "vendors/plugins/bootstrap-datepicker";
  //@import "vendors/plugins/bootstrap-daterangepicker";
  //@import "vendors/plugins/tempusdominus-bootstrap-4";
  //@import "vendors/plugins/bootstrap-markdown";
  //@import "vendors/plugins/bootstrap-maxlength";
  //@import "vendors/plugins/bootstrap-notify";
  //@import "vendors/plugins/bootstrap-select";
  //@import "vendors/plugins/bootstrap-session-timeout";
  //@import "vendors/plugins/bootstrap-switch";
  //@import "vendors/plugins/bootstrap-timepicker";
  //@import "vendors/plugins/bootstrap-touchspin";
  //@import "vendors/plugins/ckeditor";
  //@import "vendors/plugins/datatables";
}
  @import "vendors/plugins/dropzone";
.metronic-scope {
  //@import "vendors/plugins/duallistbox";
  //@import "vendors/plugins/fontawesome5";
  //@import "vendors/plugins/fullcalendar";
  //@import "vendors/plugins/gmaps";
  //@import "vendors/plugins/ion-range-slider";
  //@import "vendors/plugins/jquery-repeater";
  //@import "vendors/plugins/jqvmap";
  //@import "vendors/plugins/jstree";
  //@import "vendors/plugins/kanban";
  //@import "vendors/plugins/nouislider";
  @import "vendors/plugins/perfect-scrollbar";
  //@import "vendors/plugins/quill";
  //@import "vendors/plugins/recaptcha";
  //@import "vendors/plugins/select2";
  //@import "vendors/plugins/summernote";
}
  @import "vendors/plugins/sweetalert2";
.metronic-scope {
  //@import "vendors/plugins/tagify";
  //@import "vendors/plugins/tinymce";
  //@import "vendors/plugins/toastr";
  //@import "vendors/plugins/typeahead";
  //@import "vendors/plugins/uppy";
  //@import "vendors/plugins/draggable";
  //@import "vendors/plugins/prismjs";
  //@import "vendors/plugins/apexcharts";
  //@import "vendors/plugins/leaflet";
}

.metronic-scope {
  // 5: Layout
  @import "layout/init";
}
////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY ABOVE INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////
