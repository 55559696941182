
$base-spacing-unit: 24px;
$half-spacing-unit: 12px;
$border-radius-global: 5px;
$button-width: auto;
$button-height: 50px;
$button-text-color: white;

$social-list: (
        facebook #4b70ab,
        linkedin #0087be,
        google #3F85F4,
        twitter #3B94D9,
        yahoo #500095,
        openid #F78C40,
        googleplus #DD4B39,
        dropbox #007DE1,
        github #333,
        bitbucket #205081,
        soundcloud #FF5500,
        spotify #2EBD59,
        microsoft #00A4EF,
        flickr #FF0084
);

.social-login {

  %button,
  .button {
    width: $button-width;
    display: inline-block;
    padding: 0 ($base-spacing-unit - ($half-spacing-unit / 2)) 0 ($half-spacing-unit / 2);
    border: 0 none;
    border-radius: $border-radius-global;
    text-decoration: none;
    transition: all 250ms linear;

    &:hover {
      text-decoration: none;
    }

  }

  .button--social-login {
    margin-bottom: $half-spacing-unit;
    margin-right: $half-spacing-unit;
    color: $button-text-color;
    height: $button-height;
    line-height: $button-height - 4;
    position: relative;
    text-align: left;
    width:100%;

    .icon {
      margin-right: $half-spacing-unit;
      font-size: $base-spacing-unit;
      line-height: $base-spacing-unit;
      width: ($base-spacing-unit * 2) - ($half-spacing-unit / 2);
      height: $base-spacing-unit;
      text-align: center;
      display: inline-block;
      position: relative;
      top: 4px;

      &:before {
        display: inline-block;
        width: ($base-spacing-unit * 2) - ($half-spacing-unit / 2) - 2;
      }

      &:after {
        content:"";
      }

    }

  }

  @each $name, $color in $social-list {

    .button--#{$name} {
      background-color: $color;
      border: 1px solid darken($color, 10%);

      .icon {
        border-right: 1px solid darken($color, 10%);

        &:after {
          border-right: 1px solid lighten($color, 10%);
        }
      }

      &:hover {
        background-color: darken($color, 5%);
      }

    }

  }

}

