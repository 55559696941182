.nav-multiselect {
  display: flex;
  flex-flow: row nowrap;
  overflow-y: visible !important;
  height: 54px;
  user-select: none;
}

.zm-multiWrap {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  position: relative;
}

/* style the multiselect used from bootstrap-select */
.zm-multiWrap {
  .bootstrap-select {
    > .dropdown-toggle {
      &.bs-placeholder,
      &.bs-placeholder:hover,
      &.bs-placeholder:focus,
      &.bs-placeholder:active {
        color: #000;
      }
    }
    &.form-control {
      border-radius: 27px;
      padding: 0.3rem 0.2rem;
    }
    .btn-zamiastowi {
      .filter-option-inner-inner {
        text-align: center;
        font-family: Atten New Bold,sans-serif;
        font-weight: 400 !important;
        line-height: 1;
        font-size: 1rem;
      }
    }
    .dropdown-menu {
      .check-mark {
        color: $primary;
      }
      li {
        padding: 0.6rem 0;
        .dropdown-item {
          font-weight: 400 !important;
          &.selected {
            font-weight: 600 !important;
            span.check-mark {
              right: 25px;
              top: 18px;
            }
          }
        }
        &:hover {
          background-color: $primary;
          color: white;
          .check-mark {
            color: white;
          }
        }
      }
    }
  }
}