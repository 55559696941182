/*------------------------------------
  File for your custom SCSS style
------------------------------------*/

h1, h2, h3, .h1, .h2, .h3,{
  margin-top: 0;
  margin-bottom: 1.5rem;
}

// Header
.header {
  .navbar-nav {
    font-family: $font-family-bold;
    font-size: 40px;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-xs;
      text-transform: uppercase;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    &-item {
      &.active {
        @include media-breakpoint-up(lg) {
          font-family: $font-family-extra-bold;

          a {
            border-bottom: solid 2px $primary;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      .nav-link {
        color: $black;
        display: block;
        padding: {
          top: 1.5rem;
          bottom: 1.5rem;
        }
      }

      .active > .nav-link {
        color: $primary;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .navbar-expand-lg.navbar {
      padding: {
        top: 1rem;
        bottom: 1rem;
      }
    }
  }

  .navbar {
    transition: padding 0.15s ease-in-out;
  }

  &.scrolled {
    .navbar {
      padding: {
        top: $navbar-padding-y/3;
        bottom: $navbar-padding-y/3;
      }
    }
  }

  &-login {
    background: url(#{$root-path}img/icons/user-primary.svg) no-repeat 0 50%;
    padding-left: 20px;
    line-height: 1;
    font-size: 10px;
    font-family: $font-family-medium;
  }
}

@include media-breakpoint-down(md) {
  .navbar-expand-lg
    .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
    border: none;
    box-shadow: $box-shadow-sm;
    border-radius: $border-radius;
  }
}

.text {
  &-muted-darker {
    color: $text-muted-darker;
  }

  &-underline {
    text-decoration: underline;
  }

  &-line-through {
    text-decoration: line-through;
  }

  &-lh-xs {
    line-height: $text-lh-xs;
  }
}

.link {
  &-dark {
    transition: color 0.3s ease-in-out;
  }

  &-dark {
    color: $dark !important;

    &:hover {
      color: $primary !important;
    }
  }

  &-white {
    color: $white !important;

    &:hover {
      color: $primary !important;
    }
  }
}

.child-zoom-onhover {
  &-elem {
    transition: transform 0.3s ease-in-out;
  }
  &:hover &-elem {
    transform: scale(1.1);
  }
}

.rounded {
  &-left-pill {
    border-top-left-radius: $rounded-pill;
    border-bottom-left-radius: $rounded-pill;
  }
  &-right-pill {
    border-top-right-radius: $rounded-pill;
    border-bottom-right-radius: $rounded-pill;
  }

  @include media-breakpoint-up(lg) {
    &-lg-display-lg {
      border-radius: $border-radius-lg;
    }
    &-display-lg {
      border-radius: $border-radius;
    }
  }

  @include media-breakpoint-up(md) {
    &-lg-display-md {
      border-radius: $border-radius-lg;
    }
    &-display-md {
      border-radius: $border-radius;
    }
  }

  @include media-breakpoint-down(md) {
    &-lg-display-down-md {
      border-radius: $border-radius-lg;
    }
    &-display-down-md {
      border-radius: $border-radius;
    }
  }
}

.raiting-stars {
  color: $raiting-stars;

  &-plus {
    color: $raiting-stars-plus;
  }
}

/* begin slick carousel tuning */

.slick-slide {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  .btn-wide {
    min-width: 15rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.slick-gutters-3 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  .slick-slide {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.slick-center-mode-right {
  margin-right: $slick-center-mode-right !important;

  &-offset {
    .draggable {
      padding-right: $slick-center-mode-right-offset-padding-x;
    }
  }

  .slick-list {
    padding-left: 0 !important;
  }

  &-0 {
    margin-right: calc(-1px - ((100vw - 100%) / 2)) !important;
  }
}

/* end slick carousel tuning */

.bg {
  &-body {
    background-color: $body-bg !important;
  }

  @include media-breakpoint-up(sm) {
    &-sm-transparent {
      background-color: transparent !important;
    }
  }

  @include media-breakpoint-up(lg) {
    &-lg-transparent {
      background-color: transparent !important;
    }
  }

  &-pseudo-warning {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: $warning;
      height: 81.9%;
      z-index: -1;

      @include media-breakpoint-up(lg) {
        height: 73.25%;
      }
      @include media-breakpoint-up(xl) {
        height: 72.5%;
      }
    }
  }

}



.left-info-bar {
  @include media-breakpoint-up(lg) {
    position: relative;
    margin-top: rem-calc(-236px);
    z-index: 2;
    // background-color: $white;
  }

  @include media-breakpoint-down(md) {
    .card {
      box-shadow: none;
      background-color: transparent;
    }
  }

  &-image {
    position: relative;

    @include media-breakpoint-down(md) {
      border-radius: $border-radius;
      overflow: hidden;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background: linear-gradient(rgba(84, 84, 84, 0) 0%, #000 100%);
      opacity: 0.53;
    }

    &-count {
      position: absolute;
      color: $white;
      font-size: $small-font-size;

      @include media-breakpoint-up(lg) {
        bottom: 1rem;
        right: 1rem;
      }

      @include media-breakpoint-down(md) {
        top: 50%;
        left: 50%;
        transform: scale(2);
      }
    }
  }

  &-map {
    height: 250px;

    @include media-breakpoint-down(md) {
      height: 240px;
      border-radius: $border-radius;
      overflow: hidden;
    }
  }

  &-address {
    padding: 1.25rem 0;

    @include media-breakpoint-up(lg) {
      padding: 21px 28.3px;
    }
  }

  &-list {
    .list-group-item {
      padding: 15px 28.3px 14px;
      background-color: transparent;

      @include media-breakpoint-down(md) {
        padding: 1rem 0;
      }

      &-description {
        padding: 20.4px 28.3px 21.7px;

        @include media-breakpoint-down(md) {
          padding: 1.25rem 0;
        }

        &__icon {
          margin-right: 41.7px;
          margin-top: -2px;
        }
      }
    }
  }

  &-price {
    padding: 21px 28.3px;
    text-align: center;
  }

  &-btn-outline {
    text-decoration: underline;

    @include media-breakpoint-down(md) {
      display: block;
      padding: 1rem 1.5rem;
      font: 1rem/1.5 "Atten New Regular", sans-serif;
      text-decoration: inherit;
      border: solid 1px $primary;
      color: $primary;
      border-radius: 27px;
    }
  }
}

.rooms-available {
  .card {
    box-shadow: none;

    .card-header {
      border: none;
    }
  }
}

.recommended-slider {
  .slick-slide {
    opacity: 0.3;
    transition: 0.4s;

    &.slick-active {
      opacity: 1;
    }
  }
}

.thread {
  p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  h2 {
    line-height: normal;
  }

  .thread-space {
    @include media-breakpoint-up(lg) {
      padding: 36px 85px 22px 85px;
    }
  }
}

// .custom-pagination {
//   .page-item {
//     margin: 0;

//     .page-link:not(.arrow-btn) {
//       padding: 0.7rem 1.75rem;
//       box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.03);
//       border-radius: 0;
//       border-left: 1px solid #eee;
//     }

//     .page-link.arrow-btn {
//       padding: 0.7rem 1.75rem;

//       &:hover,
//       &:active,
//       &focus {
//         background: none !important;
//       }
//     }

//     &:nth-child(2) .page-link {
//       border-left: none;
//       border-top-left-radius: $border-radius-pill;
//       border-bottom-left-radius: $border-radius-pill;
//     }

//     &:nth-child(5) .page-link {
//       border-top-right-radius: $border-radius-pill;
//       border-bottom-right-radius: $border-radius-pill;
//     }
//   }
// }

.promo-icons {
  @include media-breakpoint-up(lg) {
    position: absolute;
    right: 0.5rem;
    bottom: 2rem;
  }

  // @include media-breakpoint-down(md) {
  //   bottom: -45px;
  //   right: 20px;
  // }

  a {
    width: 35px;
    height: 24px;
    text-align: center;
  }
}

.btn-hover {
  &-primary:hover {
    background-color: $primary;
    border-color: $primary;
  }
}

.btn {
  font-family: $font-family-base;

  &-lg {
    @include media-breakpoint-down(md) {
      padding: {
        left: 25px;
        right: 25px;
      }
    }
  }

  &-xl {
    font: $btn-icon-font-size-xl $font-family-bold;
    padding: $input-btn-padding-y-xl $input-btn-padding-x-xl;
    border-radius: 27px;
  }

  &-facebook {
    color: $white;
    background-color: $facebook;

    &:hover {
      color: $white;
      background-color: darken($facebook, 5%);
      box-shadow: 0 4px 11px rgba(darken($facebook, 5%), 0.35);
    }
  }

  &-google {
    color: $dark-lighter;
    background-color: $google;

    &:hover {
      color: $dark-lighter;
      background-color: darken($google, 5%);
      box-shadow: 0 4px 11px rgba(darken($google, 5%), 0.35);
    }

    &-2 {
      color: $dark-lighter;
      background-color: darken($google, 3%);

      &:hover {
        color: $dark-lighter;
        background-color: darken($google, 7%);
        box-shadow: 0 4px 11px rgba(darken($google, 7%), 0.35);
      }
    }
  }

  @include media-breakpoint-up(sm) {
    &-sm-wide {
      min-width: $btn-wide;
    }
  }
}

.dropdown-toggle {
  &-before {
    &::before {
      display: inline-block;
      font-family: $dropdown-toggle-pseudo-font-family;
      font-size: $dropdown-toggle-pseudo-font-size;
      font-weight: 900;
      content: $dropdown-toggle-pseudo-content;
      margin-right: $dropdown-toggle-pseudo-margin-x;
      transition: $dropdown-toggle-arrow-transition;
    }

    &.hs-active {
      &::before {
        transform: $dropdown-toggle-arrow-collapse-rotation;
      }
    }

    &::after {
      display: none;
    }

    .dropdown-item-icon {
      margin-right: 0;
    }
  }

  &-inverse {
    &.dropdown-toggle-before {
      &::before {
        transform: $dropdown-toggle-arrow-collapse-rotation;
      }

      &.hs-active {
        &::before {
          transform: rotate(0);
        }
      }
    }
  }
}

.dropdown {
  &-toggle-globe {
    .dropdown-item-icon {
      min-width: 19px;
      max-width: 19px;
    }
  }
}

.opacity-0 {
  opacity: 0 !important;
}

.slick-blurex {
  //.slick-slide:not(.slick-active) {
  //  opacity: 0.3;
  //  cursor: pointer;
  //}
  .slick-slide.slick-hidden {
    opacity: 0;
  }
  .slick-slide:not(.slick-active):not(.slick-hidden) {
    opacity: 0.3;
  }
  .slick-slide[aria-hidden="true"] {
    cursor: pointer;
  }
  .slick-slide[aria-hidden="true"].slick-semi-visible {
    opacity: 0.3;
  }
  // .slick-slide[aria-hidden="true"] {
  //   opacity: .3;
  // }
}
.slick-blurex-cloned-opacity {
  .slick-cloned[tabindex="-1"]:not(.slick-active) {
    opacity: 0;
  }
}

.form-control {
  font-family: $font-family-book;
}
.btn-lg {
  font-family: $font-family-base;
}

.pagination {
  font-size: $small-font-size;

  .page-item {
    .page-link {
      border-radius: 0;
      padding: {
        top: 1.1rem;
        bottom: 1.1rem;
      }
      border-left: solid 1px $border-color;

      min-width: $pagination-min-width / 1.5;

      @include media-breakpoint-up(sm) {
        min-width: $pagination-min-width;
      }

      &:hover {
        background: $white;
      }
    }

    &:first-child,
    &:last-child {
      .page-link {
        background-color: transparent;
        border: 0;
        min-width: auto;
        color: $primary;
      }
    }

    &:first-child {
      margin-left: -40px;
    }

    &:last-child {
      margin-right: -40px;
    }

    &:nth-child(2) {
      .page-link {
        border-top-left-radius: $border-radius-pill;
        border-bottom-left-radius: $border-radius-pill;
        border-left: 0;
      }
    }
    &:nth-last-child(2) {
      .page-link {
        border-top-right-radius: $border-radius-pill;
        border-bottom-right-radius: $border-radius-pill;
      }
    }

    &.disabled .page-link {
      color: $border-color;
    }

    &.active {
      .page-link {
        color: $primary;
        background-color: $body-bg;
      }
    }
  }
}

.btn-group-smiles {
  .btn {
    padding: 7px 25px 3px;
    height: 32px;
    color: $primary;
    background-color: #f5f5f5;
    border-radius: 0;
    border-radius: $border-radius-pill;
    margin-left: 0 !important;
    font-size: 16px;

    &:not(:first-child) {
      border-left: 0.5px solid $border-color !important;
    }

    &.active {
      color: $white;
      background-color: $primary;
      z-index: 2;

      &:not(:first-child) {
        border-left-color: $primary !important;
      }

      &:not(:last-child) {
        margin-right: -0.5px;
      }
    }

    &:first-child {
      // border-top-left-radius: $border-radius-pill;
      // border-bottom-left-radius: $border-radius-pill;
      // border-right: 2px solid #eee;
    }

    &:last-child {
      // border-top-right-radius: $border-radius-pill;
      // border-bottom-right-radius: $border-radius-pill;
      // border-left: 1px solid #eee;
    }
  }
}

.card-bordered-left {
  border-left: 4px solid $primary;
}

// .gallery-custom {
//   img {
//     margin-right: 16px;
//   }

//   a:not(:last-child) img {
//     margin-bottom: 16px;
//   }
// }

.simple-select {
  color: $primary;
  cursor: pointer;

  &::after {
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-size: 80%;
    font-weight: 900;
    content: "\f107";
    margin-left: 0.5rem;
    transition: 0.3s;
  }
}

.select2-container--open .select2-dropdown {
  border: none;
  box-shadow: $box-shadow-sm;
  border-radius: $border-radius;
  overflow: hidden;
}
.select2-container--default .select2-results__option {
  padding: $nav-classic-nav-link-padding-y/2 $nav-classic-nav-link-padding-x;
  border-bottom: solid 1px lighten($border-color, 10%);
  color: $primary;

  &[aria-selected="true"] {
    background-color: $primary;
    color: $white;

    &::after {
      display: none;
    }
  }

  &--highlighted[aria-selected]:not([aria-selected="true"]) {
    color: $primary;
    background-color: rgba($primary, 0.075);
  }
}

.list-check {
  list-style: none;
  padding-left: 0;

  li {
    background: url(#{$root-path}img/icons/check.svg) no-repeat 0 5px;
    padding-left: 2rem;
  }
}

.nav-statusy {
  border: 0;
  @include media-breakpoint-down(md) {
    margin-top: 0 !important;
  }

  .nav-link {
    position: relative;
    // font-size: $small-font-size;
    border-bottom: solid 1.5px #e8e8e8;
    @include media-breakpoint-down(md) {
      border-bottom: 0;
    }

    &.active {
      font-family: $font-family-bold;
      color: $primary !important;
      border-color: $primary !important;
    }

    .badge {
      position: absolute;
      top: 15px;
      font-size: $font-size-2xs;
      min-width: 20px;
      height: 20px;
      margin-left: 1rem;

      // @include media-breakpoint-up(lg) {
      //   right: 0;
      // }
    }
  }
}

.messages-list {
  .messages-item {
    padding: 14.5px 21px;
    // font-size: $small-font-size;
    border-bottom: 1px solid $border-color;
    color: $dark;

    &:hover {
      background-color: $white;
    }

    &.active {
      color: $primary;
      font-weight: bold;
      background-color: $white;
    }

    .badge {
      min-width: 20px;
      height: 20px;
      font-size: $font-size-2xs;
    }
  }
}

.messages-settings-list {
  .messages-settings-item {
    padding: 14.5px 21px;
    // font-size: 12px;
    border-bottom: 1px solid $border-color;

    &:hover {
      background-color: $white;
    }
  }
}

.checked-image {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.invalid-feedback {
  display: none;
  color: $primary;
  right: 40px;
  bottom: 10px;
  position: absolute;
  width: auto;
  margin-top: 0.25rem;
  font-size: $small-font-size;
  z-index: 9;
  color: $danger;
}

@include media-breakpoint-down(md) {
  .d-down-md-flex {
    display: flex !important;
  }
}

.input-btn-pill {
  .form-control,
  .btn {
    border-radius: $border-radius-pill-lg;
  }
  @include media-breakpoint-down(sm) {
    .form-control {
      border-top-right-radius: $border-radius-pill-lg !important;
      border-bottom-right-radius: $border-radius-pill-lg !important;
    }
    .btn {
      border-top-left-radius: $border-radius-pill-lg !important;
      border-bottom-left-radius: $border-radius-pill-lg !important;
    }
  }
}

.border {
  @include media-breakpoint-up(md) {
    &-md {
      &-bottom {
        border-bottom: solid 1px $border-color;
      }
      &-top-0 {
        border-top: 0 !important;
      }
      &-bottom-0 {
        border-bottom: 0 !important;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &-lg {
      &-bottom {
        border-bottom: solid 1px $border-color;
      }
      &-top-0 {
        border-top: 0 !important;
      }
      &-left {
        border-left: solid 1px $border-color;
      }
      &-bottom-0 {
        border-bottom: 0 !important;
      }
    }
  }
}

.badge-status {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.hs-active .rotate-on-active {
  transform: rotateX(180deg);
}

.custom-unfold-menu {
  padding: 0;
  .dropdown-item {
    padding: 5px 30px;
    text-align: center;
    color: $primary;

    &:hover {
      color: $white;
      background-color: $primary;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #d8d8d8;
    }
  }
}

.table-head {
  padding: {
    left: 0.5rem;
    right: 0.5rem;
  }
  @include media-breakpoint-up(lg) {
    padding-left: 85px;
    padding-right: 85px;
  }
}

.table {
  thead {
    th {
      font-size: 14px;
      font-weight: bold;
      text-transform: none;
    }
  }
  tbody {
    td {
      font-weight: normal;
    }
  }
}

.table-align-bottom {
  tbody td {
    vertical-align: bottom;
  }
}

.table-reservations {
  @include media-breakpoint-down(sm) {
    display: block;
  }

  tbody {
    @include media-breakpoint-down(sm) {
      display: block;
    }
    tr {
      @include media-breakpoint-down(sm) {
        display: block;
        border: none;
        padding: rem-calc(10px 0);
      }
      font-size: rem-calc(14px);
      line-height: 1.2;
      border-bottom: 1px solid $gainsboro;

    }
    span {
      padding: rem-calc(0 15px 0 0);

      @include media-breakpoint-down(sm) {
        display: block;
        padding: rem-calc(4px 0);
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
    th {
      padding: rem-calc(17px 0);
      border: none;
      @include media-breakpoint-down(sm) {
        display: block;
        padding: rem-calc(4px 0);
        font-weight: normal;
      }
      @include media-breakpoint-up(lg) {
        padding-left: rem-calc(85px);
      }
    }
    td {
      padding: rem-calc(17px 0);
      border: none;
      @include media-breakpoint-down(sm) {
        display: block;
        padding: rem-calc(4px 0);
      }
    }
  }
}

.oferte-diasble {
  filter: grayscale(1);
}

.bg-light-custom {
  background-color: #fafafa;
}

.reserve-custom-select {
  width: 154px;
  background: #fff url("../../assets/img/icons/arrow-mini-primary.svg")
    no-repeat right 1rem center/.7rem 0.7rem;
  border-radius: $border-radius-pill;
  background-color: #fafafa;
  border-color: #fafafa;
  color: $primary;

  &.custom-select {
    border-color: #fd521e;
    border-radius: 27px;
  }
  &.no-border {
    border: 0;
  }
}

.metronic-scope {
  .steps-nav-pilled {
    @include media-breakpoint-down(md) {
      text-align: center;
    }

    &-nav {
      &.nav-pills {
        line-height: 1;
        background-color: $white;
        border-radius: $border-radius-pill;
        overflow: hidden;
      }

      .nav-item {
        .nav-link {
          position: relative;
          padding: 1.2rem 3rem;
          color: #677788;

          &:hover {
            color: #677788;
          }
          &::after:hover {
            color: #677788;
          }
        }

        &[data-wizard-state="current"] {
          .nav-link {
            background-color: transparent;
            color: $primary;
            font-weight: bold;

            @include media-breakpoint-up(lg) {
              background-color: $light-lighter;
            }
          }
        }

        &[data-wizard-state="done"] {
          .nav-link {
            color: #76ba40;
            @include media-breakpoint-down(md) {
              background-color: $light-lighter;
            }
          }
        }

        @include media-breakpoint-down(md) {
          flex: 0 0 100%;
          max-width: 100%;

          &:not(:last-child) {
            border-bottom: solid 1px $border-color;
          }
        }

        @include media-breakpoint-up(lg) {
          &:not(:first-child) {
            border-left: solid 1px $border-color;
          }
        }

        &:not(:last-child) {
          .nav-link {
            &::after {
              content: "\f105";
              font-family: "Font Awesome 5 Free";
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              top: 50%;
              right: 0;
              width: 14px;
              font-size: 10px;
              color: #fff;
              height: 14px;
              background-color: #d8d8d8;
              border-radius: 50%;
              transform: translate(50%, -50%);
              font-weight: 600;
              z-index: 2;
            }

            @include media-breakpoint-down(md) {
              &::after {
                top: 100%;
                right: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }

          &[data-wizard-state="current"] {
            .nav-link::after {
              background-color: #fd521e;
            }
          }

          &[data-wizard-state="done"] {
            .nav-link {
              @include media-breakpoint-up(lg) {
                z-index: 1;
              }

              &::after {
                background-color: #76ba40;
                content: "\f00c";
                font-size: 8px;
              }
            }
          }

        }
      }
    }
  }
}

.price-card-bordered {
  border: 2px solid $primary;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
  border-color: $primary;
}

.custom-control-alt.custom-checkbox {
  .custom-control-label::before {
    background-color: #ebebeb;
    border-color: #ebebeb;
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    height: 35px;
    width: 35px;
    top: -5px;
    left: -34px;
    background-image: url("../../assets/img/icons/checkbox-checked.svg");
  }
}

.cursor-zoom {
  cursor: zoom-in;
}

@include media-breakpoint-up(lg) {
  .left-lg-n7 {
    left: -3rem !important;
  }
  .right-lg-n7 {
    right: -3rem !important;
  }
}

.user-border-right {
  padding-right: 5px;
  margin-right: 5px;
  border-right: 1px solid #d8d8d8;
}

.avatar-lg.avatar-circle .avatar-lg-status {
  bottom: -0.20313rem;
  right: -0.20313rem;
}

a {
  &.review {
    margin-right: 4px;
    &:hover {
      color: white;
    }

    &[href=""],
    &[href="#"] {
      display: none;
    }
  }
}

.btn-extra-sm {
  padding: 0.125rem 0.825rem;
  font-size: 0.7375rem;
  line-height: 1;
  border-radius: 0.25rem;
}

.view {
  span {
    display: inline-block;
    width: 60pt;
  }
}

.card {
  &-body {
    padding: $card-spacer-y $card-spacer-x;
  }
}

.truncate-overflow {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@include media-breakpoint-up(lg) {
  .img-lg-fluid-none {
    max-width: none;
    height: auto;
  }
}

.alignment-input-text {
  margin-left: 1.125rem;
  padding-left: 1px;

  @include media-breakpoint-up(lg) {
    &-lg {
      margin-left: 1.125rem;
      padding-left: 1px;
    }
  }
}

.is-invalid {
  &.form-control::placeholder {
    color: transparent;
  }
}

.gradient-y-overlay-dark {
  &::before {
    @include gradient-y(
      $start-color: rgba(#696969, 0.18),
      $end-color: rgba(#000, 0.62)
    );
  }
}

/* Nav Content */
.nav-content {
  background-color: $body-bg;

  @include media-breakpoint-up(lg) {
    .nav-item {
      border-bottom: solid 1.5px #e8e8e8;

      &:not(:last-child) {
        padding-right: 0.5rem;
      }
    }
  }

  .nav-link {
    padding-bottom: 0.25rem;
    border-bottom: solid 1.5px transparent;
    margin-bottom: -1.5px;

    &.active {
      border-color: $primary;
    }
  }
}

.fc .fc-daygrid-event {
  margin-top: 16px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin: 8px;
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 1rem;
}

.dropdown-menu--statuses {
  padding: 0;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.04);
  border-radius: 13px;
  background-color: #ffffff;

  .dropdown-item {
    padding: 10px;
    color: #fd521e;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    text-align: center;
    background: #fff;

    & + .dropdown-item {
      border-top: 1px solid #ddd;
    }

    &:hover,
    &.active {
      color: #fff;
      background: #fd521e;
    }

    &:first-child {
      border-radius: 13px 13px 0 0;
    }
    &:last-child {
      border-radius: 0 0 13px 13px;
    }
  }
}

/* spaces */
.custom-control {
  padding-left: 2.3rem;
}

.custom-control-label::before,
.custom-control-label::after {
  top: 2px;
  left: -1.7rem;
}

.form-group {
  label {
    color: #000000;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
  }
}

.footer {
  &-title {
    margin-bottom: 0.75rem;
  }
  .bg-light {
    background-color: #faf7f7 !important;
  }
}

.shadow {
  &-hover:hover {
    box-shadow: $box-shadow !important;
  }

  @include media-breakpoint-up(lg) {
    &-sm-display-lg {
      box-shadow: $box-shadow-sm !important;
    }
    &-hide-lg {
      box-shadow: none !important;
    }
  }
}

.visibility {
  &-hidden {
    visibility: hidden;
  }

  @include media-breakpoint-up(lg) {
    &-lg-visible {
      visibility: visible;
    }
  }
}

.max-w {
  &-100 {
    max-width: 100%;
  }

  @include media-breakpoint-up(xl) {
    &-xl-100 {
      max-width: 100%;
    }
  }
}

@include media-breakpoint-up(xl) {
  .bg-lg-light {
    background-color: $light;
  }
}

.z-tab,
.nav-statusy {
  @include media-breakpoint-down(md) {
    display: block;
    position: relative;
    background: #fff;
    border-radius: 26px;
    cursor: pointer;
    padding: rem-calc(52px 0 0 0);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
    margin-top: rem-calc(-50px);

    &::after {
      content: "\f107";
      position: absolute;
      top: rem-calc(17px);
      right: 2rem;
      display: inline-block;
      font: 600 1rem "Font Awesome 5 Free";
      color: $primary;
    }

    .nav-link {
      pointer-events: none;
      position: relative;
      padding: rem-calc(13px 30px 12px 30px);
      height: rem-calc(52px);
      text-align: left;
      display: none;

      &.active,
      &.router-link-active {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        border: none;
        font-family: $font-family-bold;
        order: 0;
        color: $primary;
      }
    }

    &.opened {
      .nav-link {
        pointer-events: auto;
        display: block;
      }
    }

  }
}

.z-footnote {
  background-color: #f7f7f7;
  border: {
    style: solid;
    width: 1px 0;
    color: $primary;
  }

  @include media-breakpoint-up(lg) {
    border-color: $border-color;
  }

  &-btn {
    color: $black;
    font-size: 0.875rem;
    font-family: $font-family-bold;
    margin-bottom: 0.5rem;
    line-height: 1.4;

    @include media-breakpoint-down(md) {
      position: relative;
      bottom: -2.25rem;
      color: $white;
      border-radius: 27px;
      padding: 1rem 2rem;
      background-color: $primary;
      border-color: #fd521e;
    }
  }
}

// .content-authentication {
//   @include media-breakpoint-down(md) {
//     background-color: $light;
//   }
// }

@include media-breakpoint-down(md) {
  .fc .fc-toolbar {
    display: block;
  }
  .fc-toolbar-chunk {
    &:first-child,
    &:last-child {
      width: 100%;
      text-align: left;
    }
  }
  .fc .fc-toolbar-title {
    display: block;
    margin: {
      left: 0 !important;
      bottom: 1.5rem;
    }
    padding-top: 0.75rem;
  }
  .fc-showInCalendar-button {
    margin-bottom: 1rem;
  }
  .fc-view-type {
    margin-left: 0 !important;
  }

  .fc-daygrid {
    overflow-x: auto;
  }
  .fc .fc-scrollgrid,
  .fc .fc-scrollgrid table {
    table-layout: auto !important;
  }
  .fc-col-header {
    width: 750px !important;
  }
  .fc-daygrid-body {
    width: 750px !important;
  }
  .fc-scrollgrid-sync-table {
    width: 750px !important;
  }
  .fc-col-header-cell,
  .fc-daygrid-day {
    min-width: 120px;
  }
}


@include media-breakpoint-down(md) {
  .full-width-md {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
  }
}

@include media-breakpoint-down(xs) {
  .full-width-xs {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
    padding: 0 0;
  }
}

@include media-breakpoint-down(xs){
  .text-center-mobile {
    text-align: left;
  }
}

@include media-breakpoint-down(md) {
  .mobile-hero-height {
    height: 289px;
  }
}

@include media-breakpoint-down(md) {
  .mobile-input-position {
    position: absolute;
    top: 60px;
  }
}

@include media-breakpoint-up(lg) {
  .bg-light-lg {
    background-color: #faf7f7;
  }
}

@include media-breakpoint-up(lg) {
  .flex-lg {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    padding-bottom: 19px;
  }
}

@include media-breakpoint-up(lg) {
  .login-input-label {
    display: none;
  }
}

// Opacity
@for $opacity from 1 through 20 {
  .do-#{$opacity * 5} {
    opacity: ($opacity * 5 / 100) !important;
  }
}

.fancybox-iframe-map {
  .card-header {
    padding-top: 5px;
    padding-bottom: 5px;

    .card-title {
      .h3, h3 {
        font-size: 0.8rem;
        margin-bottom: 0;
      }
    }
  }
}