
.fc-daygrid-event {
  &.fc-event-free {
    border-color: #76BA40;
    background-color: #76BA40;
    span {
      color: #76BA40;
    }
  }
  &.fc-event-reserved {
    border-color: #FD521E;
    background-color: #FD521E;
    span {
      color: #FD521E;
    }
  }
  &.fc-event-occupied {
    border-color: #C72113;
    background-color: #C72113;
    span {
      color: #C72113;
    }
  }
  &.fc-event-not-available {
    border-color: #666;
    background-color: #666;
    span {
      color: #666;
    }
  }
}