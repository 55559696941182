.places-outer-container {
    // @extend .mt-15;
    // @extend .mt-lg-0;
    // // @extend .space-top-5;
    // @extend .space-top-lg-3;
    padding-top: 2rem;

    @include media-breakpoint-up(sm) {


    }
    @include media-breakpoint-up(md) {

    }
    @include media-breakpoint-up(lg) {

    }

}

.results-and-sorting-wrap {
    @extend .d-lg-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
    @extend .mb-3;
    @extend .mb-lg-9;
    @include media-breakpoint-up(lg) {
        width: calc(10 / 12 * 100%);
        margin: auto;
    }
}