.cms-ce {
  &__gallery {
    margin-bottom: 3em;
  }
  &__space {
    &-before {
      &-extra-small {
        margin-top: 1em;
      }
      &-small {
        margin-top: 2em;
      }
      &-medium {
        margin-top: 4em;
      }
      &-large {
        margin-top: 6em;
      }
      &-extra-large {
        margin-top: 8em;
      }
     }
    &-after {
      &-extra-small {
        margin-bottom: 1em;
      }
      &-small {
        margin-bottom: 2em;
      }
      &-medium {
        margin-bottom: 4em;
      }
      &-large {
        margin-bottom: 6em;
      }
      &-extra-large {
        margin-bottom: 8em;
      }
    }
  }

  p {
    text-align: justify;
  }

  ol {
    list-style: none;
    counter-reset: cupcake;
    padding-left: 1.5em;

    li {
      counter-increment: cupcake;
      padding-bottom: 10px;
      text-align: justify;

      &:before {
        content: counter(cupcake) ' ';
        font-feature-settings: "tnum";
        color: $primary;
        font-weight: bold;
        padding-right: 12px;
        display: inline-block;
        width: 1.5em;
        margin-left: -1.5em
      }

      ol {
        padding-left: 1.2em;
        padding-top: 10px;
        li {
          counter-increment: cupcake;
          text-align: justify;

          &:before {
            content: counter(cupcake, lower-alpha) ') ';
            font-feature-settings: "tnum";
            color: $primary;
            font-weight: normal;
            padding-right: 12px;
            display: inline-block;
            width: 1.2em;
            margin-left: -1.2em
          }
        }
      }

    }
  }
}