@import "~placeholder-loading/src/scss/placeholder-loading";

.ph-item {
  padding: 0;
  background-color: inherit;
  border: none;

  &-product {
    .ph-picture {
      width: 80%
    }
  }
}

.ph-col {
  &-1 {
    flex: 0 0 8.333335%;
  }

  &-3 {
    flex: 0 0 25%;
  }

  &-9 {
    flex: 0 0 75.000015%;
  }
}

.ph-h {
  &-1 {
    height: 60px
  }
  &-2 {
    height: 120px
  }
  &-3 {
    height: 180px
  }
  &-4{
    height: 240px
  }
}

.ph-row {
  .extra-big {
    height: 40px;
  }
}
