$rem-base: 16px;

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@function convert-to-rem($value, $base-value: $rem-base)  {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;

  @return $value;
}

@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);

  @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }
  $remvalues: ();

  @for $i from 1 through $max {
    $remvalues: append($remvalues, convert-to-rem(nth($values, $i), $base-value));
  }

  @return $remvalues;
}