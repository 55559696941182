@import "../theme-essentials.scss";  // demo level framework config override
@import "mapbox-gl.scss";

.search-detailed {

  &__holiday {
    &--results {

      .kt-widget {

          .kt-widget__top {
            display: flex;
            align-items: flex-start;

            .kt-widget__media {
              margin-top: 0.2rem;

              img {
                width: 110px;

                //@include kt-rounded {
                //  border-radius: 8px;
                //}
              }
            }

            .kt-widget__pic {
              margin-top: 0.2rem;
              width: 100%;
              max-width: 110px;
              height: 110px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 1.5rem;

              //@include kt-rounded {
              //  border-radius: 8px;
              //}
              //
              //@each $name, $color in $kt-state-colors {
              //  &.kt-widget__pic--#{$name} {
              //    background: rgba(kt-get($color, base), 0.1);
              //  }
              //}
            }

            .kt-widget__content {
              width: 100%;
              padding-left: 1.7rem;

              .kt-widget__head {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;

                .kt-widget__user {
                  display: flex;
                  align-items: center;
                }

                .kt-widget__username {
                  font-size: 1.3rem;
                  color: kt-base-color(label, 4);
                  font-weight: 500;
                  align-items: center;

                  &:hover {
                    color: kt-brand-color();
                    transition: color 0.3s ease;
                  }

                  i {
                    font-size: 1.2rem;
                    color: #57c974;
                    padding-left: 0.5rem;
                  }
                }

                .kt-widget__action {
                  .btn {
                    font-weight: 600;
                    margin-left: 0.5rem;
                  }
                }
              }

              .kt-widget__subhead {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                padding: 0.6rem 0 0.8rem 0;

                a {
                  padding-right: 2rem;
                  color: kt-base-color(label, 2);
                  font-weight: 500;

                  &:hover {
                    color: kt-brand-color();
                    transition: color 0.3s ease;
                  }

                  i {
                    padding-right: 0.5rem;
                  }
                }

                .kt-widget__action {
                  .btn {
                    font-weight: 600;
                    margin-left: 0.5rem;
                  }
                  .kt-widget__item {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    .kt-widget__icon {
                      margin-right:3pt;
                      i {
                        font-size: 1.2rem;
                        color: #adb1c7;
                        font-weight: 400;
                      }
                    }
                  }
                  &--accommodations {
                    padding:5pt
                  }
                }

              }

              .kt-widget__info {
                display: flex;
                flex-flow: row wrap;

                .kt-widget__desc {
                  color: kt-base-color(label, 3);
                  font-weight: 400;
                  padding-right: 1rem;
                  flex-grow: 1;
                  margin-bottom: 0.5rem;
                }

                .kt-widget__progress {
                  display: flex;
                  align-items: center;
                  max-width: 500px;
                  width: 100%;
                  margin: 0.1rem 0;


                  .kt-widget__text {
                    padding-right: 1rem;
                    color: kt-base-color(label, 3);
                    font-weight: 500;
                  }

                  .kt-widget__stats {
                    padding-left: 1rem;
                    color: kt-base-color(label, 4);
                    font-weight: 600;
                    font-size: 1.1rem;
                  }
                }
              }
            }
          }

          .kt-widget__bottom {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            border-top: 1px solid kt-base-color(grey, 2);
            margin-top: 0.5rem;

            .kt-widget__item {
              display: flex;
              align-items: center;
              flex-grow: 1;
              padding: 0.5rem 1.5rem 0 0;

              .kt-widget__icon {
                i {
                  font-size: 1.5rem;
                  color: kt-base-color(label, 1);
                  font-weight: 400;
                }
              }

              .kt-widget__details {
                padding-left: 1rem;

                .kt-widget__title {
                  display: block;
                  color: kt-base-color(label, 2);
                  font-weight: 600;
                  font-size: 0.95rem;
                }

                .kt-widget__value {
                  display: none
                }

                a.kt-widget__value {
                  font-size: 0.95rem;

                  &:hover {
                    color: kt-brand-color();
                  }
                }

                .kt-section__content {
                  padding-left: 0.7rem;
                }
              }
            }
          }

          .kt-widget__form {
            margin-top: 1.5rem;
          }

      }
    }
  }

}

.jqvmap {
  height:300px;
}

.jqvmap-label
{
  position: absolute;
  display: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: kt-state-color(brand);
  color: white;
  font-family: sans-serif, Verdana;
  font-size: normal;
  padding: 5px;
  pointer-events:none;
}
.jqvmap-pin {
  pointer-events:none;
}
.jqvmap-zoomin, .jqvmap-zoomout
{
  position: absolute;
  left: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #000000;
  padding: 3px;
  color: white;
  width: 10px;
  height: 10px;
  cursor: pointer;
  line-height: 10px;
  text-align: center;
}
.jqvmap-zoomin
{
  top: 10px;
}
.jqvmap-zoomout
{
  top: 30px;
}
.jqvmap-region
{
  cursor: pointer;
}
.jqvmap-ajax_response
{
  width: 100%;
  height: 500px;
}

.jqvmap-touristic-regions {
  &--jqvmap {

  }
  &--checkboxes {
    height: 360px;
    .kt-checkbox-list {
      display: -ms-flexbox;
      display: -webkit-flex;
      -webkit-flex-flow: column wrap;
      -ms-flex-flow: column wrap;
      flex-flow: column wrap;
      height: 100%;
    }
  }
}

@supports (flex-wrap: wrap) {
  .jqvmap-touristic-regions {
    &--checkboxes {
      .kt-checkbox-list {
        display: flex;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .jqvmap-touristic-regions {
    &--checkboxes {
      height: 250px;
    }
  }
}

.search-section-mood-img {

  display: block;
  position: relative;

  &__img {
    width: 873px
  }
  &__title {
    position: absolute;
    bottom: 10pt;
    left: 10pt;
    display: block;
    z-index: 1;
    padding: 1.1rem 2.2rem;
    font-size: 3rem;
    font-weight: bolder;
    color: #FFF;
    background-color: rgba(50,50,50,0.5);
  }
  h3 {
    margin:0;
  }
}

@media only screen and (max-width: 1398px) {
  .search-section-mood-img {
    &__img {
      width: 100%
    }
    &__title {
      font-size: 3rem;
    }
  }
}

//@include kt-tablet() {
//  .search-section-mood-img {
//    &__img {
//      width: 100%
//    }
//    &__title {
//      font-size: 3rem;
//    }
//  }
//}
//
//@include kt-mobile() {
//  .search-section-mood-img {
//    &__img {
//      width: 100%
//    }
//    &__title {
//      font-size: 2.2rem;
//    }
//  }
//}
//
//@include kt-mobile {
//  .search-detailed {
//
//    &__holiday {
//      &--results {
//
//        .kt-widget {
//
//            padding-top: 0.5rem;
//
//            .kt-widget__top {
//              display: flex;
//              align-items: flex-start;
//              .kt-widget__media {
//                margin-top: 0.5rem;
//
//                img {
//                  max-width: 60px;
//                }
//              }
//
//              .kt-widget__pic {
//                width: 60px;
//                height: 60px;
//                margin-top: 0.5rem;
//                font-size: 1.3rem;
//              }
//
//              .kt-widget__content {
//                padding-left: 1rem;
//                margin-top: 0;
//
//                .kt-widget__head {
//
//                  .kt-widget__username {
//                    padding-bottom: 0.6rem;
//                  }
//                }
//
//                .kt-widget__subhead {
//                  padding: 1.2rem 0;
//
//                  a {
//                    &:not(:first-child):not(:last-child) {
//                      padding: 0.5rem 1rem 0.5rem 0;
//                    }
//                  }
//                }
//
//                .kt-widget__info {
//                  display: flex;
//                  flex-direction: column;
//
//                  .kt-widget__desc {
//                    padding-bottom: 1rem;
//                  }
//
//                  .kt-widget__progress {
//                    width: 100%;
//                  }
//                }
//              }
//            }
//
//            .kt-widget__bottom {
//              padding-top: 1rem;
//
//              .kt-widget__item {
//                padding: 1rem 1rem 0 0;
//
//                .kt-widget__icon {
//                  i {
//                    font-size: 2.5rem;
//                  }
//                }
//              }
//            }
//
//        }
//
//      }
//    }
//
//  }
//
//}
