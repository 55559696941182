@import "placeholder-loading/src/scss/placeholder-loading.scss";

$ph-grey: #ced4da;

.ph-item {
  padding: 0;
  background-color: inherit;
  border: none;
  overflow: visible;

  &-product {
    .ph-picture {
      width: 80%
    }
  }
}

.ph-item-animated {
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    background: linear-gradient(to right, rgba($ph-bg, 0) 46%, rgba($ph-bg, 0.35) 50%, rgba($ph-bg, 0) 54%) 50% 50%;
    animation: phAnimation $ph-animation-duration linear infinite;
    content: " ";
    pointer-events: none;
  }
}

.ph-col {
  &-1 {
    flex: 0 0 8.333335%;
  }

  &-3 {
    flex: 0 0 25%;
  }

  &-9 {
    flex: 0 0 75.000015%;
  }
}

.ph-h {
  &-1 {
    height: 60px
  }
  &-2 {
    height: 120px
  }
  &-3 {
    height: 180px
  }
  &-4{
    height: 240px
  }
}

.ph-row {
  .extra-big {
    height: 40px;
  }
  .large {
    height: 50px;
  }
}

.ph-button {
  &.btn {
    background-color: $ph-grey !important;
    border-color: $ph-grey !important;
    color: $ph-grey !important;
  }
}

.z-widget {
  &-tripple-teaser {
    .placeholder {

    }
  }
}