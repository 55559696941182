

/* Custom -> Iconbox */
.card {
  &-iconbox {
    p:last-of-type {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
  &-custom-background {
    @include media-breakpoint-down(md) {
      .card-body-content {
        background-image: none !important;
        padding-right: 3.5rem !important;
        div {
          padding-right: 0 !important;
        }
      }
    }
  }
}

/* Custom -> Toastr */
.toast {
  &-success {
    background-color: $primary;
  }
  &-error {
    background-color: #BD362F;
  }
  &-info {
    background-color: $secondary;
  }
  &-warning {
    background-color: #F89406;
  }
}

.btn {
  &-light-grey {
    background-color: #c2c2c2;
    color: #404040 !important;
  }
  &-invisible {
    opacity: 0 !important;
  }
  &-outline {
    &-secondary {
      border-color: $secondary !important;
    }
    &-bg-white {
      background-color: white;
    }
  }
  &-icon {
    &.btn-sm {
      font-size: 0.9rem !important;
      width: 2rem !important;
      height: 2rem !important;
    }
  }
}

.dropdown {
  .dropdown-menu {
    padding: 0;

    .dropdown-item {
      &:hover {
        &:first-child {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        &:last-child {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }

  &-primary {
    .dropdown-menu {

      .dropdown-item {
        color: $primary;

        &:hover {
          background-color: $primary;
          color: white;
        }

        &.text-danger {
          color: $danger;

          &:hover {
            background-color: $danger;
            color: white !important;
          }
        }
      }
    }
  }

  &-secondary {
    .dropdown-menu {

      .dropdown-item {
        color: $secondary;

        &:hover {
          background-color: $secondary;
          color: white;
        }

        &.text-danger {
          color: $danger;

          &:hover {
            background-color: $danger;
            color: white !important;
          }
        }
      }
    }
  }

  &-divider {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.tooltip {
  .tooltip-inner {
    max-width: 200px;
    padding: 7px 12px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    background-color: $primary;
    border-radius: .25rem;
  }

  &.bs-tooltip-auto[x-placement^=top] .arrow::before,
  &.bs-tooltip-top .arrow::before {
    margin-left: -3px;
    content: "";
    border-width: 5px 5px 0;
    border-top-color: $primary;
  }

  &.bs-tooltip-auto[x-placement^=right] .arrow::before,
  &.bs-tooltip-right .arrow::before {
    margin-top: -3px;
    content: "";
    border-width: 5px 5px 5px 0;
    border-right-color: $primary;
  }

  &.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
  &.bs-tooltip-bottom .arrow::before {
    margin-left: -3px;
    content: "";
    border-width: 0 5px 5px;
    border-bottom-color: $primary;
  }

  &.bs-tooltip-auto[x-placement^=left] .arrow::before,
  &.bs-tooltip-left .arrow::before {
    right: 0;
    margin-top: -3px;
    content: "";
    border-width: 5px 0 5px 5px;
    border-left-color: $primary;
  }
}
