.btn-hover {
  &-primary:hover {
    background-color: $primary;
    border-color: $primary;
  }
}

.btn {
  font-family: $font-family-base;

  &-lg {
    @include media-breakpoint-down(md) {
      padding: {
        left: 25px;
        right: 25px;
      }
    }
  }

  &-xl {
    font: $btn-icon-font-size-xl $font-family-bold;
    padding: $input-btn-padding-y-xl $input-btn-padding-x-xl;
    border-radius: 27px;
  }

  &-facebook {
    color: $white;
    background-color: $facebook;

    &:hover {
      color: $white;
      background-color: darken($facebook, 5%);
      box-shadow: 0 4px 11px rgba(darken($facebook, 5%), 0.35);
    }
  }

  &-google {
    color: $dark-lighter;
    background-color: $google;

    &:hover {
      color: $dark-lighter;
      background-color: darken($google, 5%);
      box-shadow: 0 4px 11px rgba(darken($google, 5%), 0.35);
    }

    &-2 {
      color: $dark-lighter;
      background-color: darken($google, 3%);

      &:hover {
        color: $dark-lighter;
        background-color: darken($google, 7%);
        box-shadow: 0 4px 11px rgba(darken($google, 7%), 0.35);
      }
    }
  }

  @include media-breakpoint-up(sm) {
    &-sm-wide {
      min-width: $btn-wide;
    }
  }
}

.btn-group-smiles {
  .btn {
    padding: 7px 25px 3px;
    height: 32px;
    color: $primary;
    background-color: #f5f5f5;
    border-radius: 0;
    border-radius: $border-radius-pill;
    margin-left: 0 !important;
    font-size: 16px;

    &:not(:first-child) {
      border-left: 0.5px solid $border-color !important;
    }

    &.active {
      color: $white;
      background-color: $primary;
      z-index: 2;

      &:not(:first-child) {
        border-left-color: $primary !important;
      }

      &:not(:last-child) {
        margin-right: -0.5px;
      }
    }

    &:first-child {
      // border-top-left-radius: $border-radius-pill;
      // border-bottom-left-radius: $border-radius-pill;
      // border-right: 2px solid #eee;
    }

    &:last-child {
      // border-top-right-radius: $border-radius-pill;
      // border-bottom-right-radius: $border-radius-pill;
      // border-left: 1px solid #eee;
    }
  }
}

.btn-extra-sm {
  padding: 0.125rem 0.825rem;
  font-size: 0.7375rem;
  line-height: 1;
  border-radius: 0.25rem;
}

.btn-img-icon {
  margin-right: 2.29rem;
  margin-top: -4px;
}
