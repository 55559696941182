/*
Flaticon icon font: Flaticon
Creation date: 20/06/2019 13:36
*/

@font-face {
  font-family: "MyFlaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
  url("./Flaticon.woff2") format("woff2"),
  url("./Flaticon.woff") format("woff"),
  url("./Flaticon.ttf") format("truetype"),
  url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "MyFlaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

.myfi:before{
  display: inline-block;
  font-family: "MyFlaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.myflaticon-beds_single:before { content: "\f100"; }
.myflaticon-beds_twin:before { content: "\f100\f100"; }
.myflaticon-beds_full:before { content: "\f104"; }
.myflaticon-beds_queen:before { content: "\f104"; }
.myflaticon-beds_king:before { content: "\f104"; }
.myflaticon-beds_bunk:before { content: "\f105"; }
.myflaticon-parkings:before { content: "\f101"; }
.myflaticon-sports-and-competition:before { content: "\f102"; }
.myflaticon-food-and-restaurant:before { content: "\f103"; }
.myflaticon-business-and-finance:before { content: "\f106"; }
.myflaticon-kid-and-baby:before { content: "\f107"; }
.myflaticon-furniture-and-household-1:before { content: "\f108"; }
.myflaticon-furniture-and-household-2:before { content: "\f109"; }
.myflaticon-car-rental:before { content: "\f10a"; }
.myflaticon-views:before { content: "\f10b"; }
.myflaticon-tv:before { content: "\f10c"; }
.myflaticon-spa:before { content: "\f10d"; }
.myflaticon-wifi:before { content: "\f10e"; }
.myflaticon-balcony:before { content: "\f10f"; }
.myflaticon-facade:before { content: "\f110"; }
.myflaticon-animal-kingdom:before { content: "\f111"; }
.myflaticon-branch:before { content: "\f112"; }
.myflaticon-agriculture:before { content: "\f113"; }
.myflaticon-animal:before { content: "\f114"; }
.myflaticon-animals:before { content: "\f115"; }
.myflaticon-animal-1:before { content: "\f116"; }
.myflaticon-animal-kingdom-1:before { content: "\f117"; }
.myflaticon-animals-1:before { content: "\f118"; }
.myflaticon-bakery:before { content: "\f119"; }
.myflaticon-apiary:before { content: "\f11a"; }
.myflaticon-bottle:before { content: "\f11b"; }
.myflaticon-animal-2:before { content: "\f11c"; }
.myflaticon-cheese:before { content: "\f11d"; }
.myflaticon-animal-kingdom-2:before { content: "\f11e"; }
.myflaticon-branch-1:before { content: "\f11f"; }
.myflaticon-animal-kingdom-3:before { content: "\f120"; }
.myflaticon-animals-2:before { content: "\f121"; }
.myflaticon-animal-3:before { content: "\f122"; }
.myflaticon-animal-4:before { content: "\f123"; }
.myflaticon-apiary-1:before { content: "\f124"; }
.myflaticon-bakery-1:before { content: "\f125"; }
.myflaticon-apple:before { content: "\f126"; }
.myflaticon-agriculture-1:before { content: "\f127"; }
.myflaticon-animals-3:before { content: "\f128"; }
.myflaticon-bottle-1:before { content: "\f129"; }
.myflaticon-cheese-1:before { content: "\f12a"; }
.myflaticon-animal-5:before { content: "\f12b"; }
.myflaticon-pig:before { content: "\f12c"; }
.myflaticon-cow:before { content: "\f12d"; }
.myflaticon-chicken:before { content: "\f12e"; }
.myflaticon-honeycomb:before { content: "\f12f"; }
.myflaticon-cheese-2:before { content: "\f130"; }
.myflaticon-eggs:before { content: "\f131"; }
.myflaticon-strawberry:before { content: "\f132"; }
.myflaticon-apple-1:before { content: "\f133"; }
.myflaticon-sunflower:before { content: "\f134"; }
.myflaticon-flour:before { content: "\f135"; }
.myflaticon-carrot:before { content: "\f136"; }
.myflaticon-tractor:before { content: "\f137"; }
.myflaticon-grapes:before { content: "\f138"; }
.myflaticon-milk:before { content: "\f139"; }
.myflaticon-cat:before { content: "\f13a"; }
.myflaticon-ostrich-facing-left:before { content: "\f13b"; }
.myflaticon-alpaca:before { content: "\f13c"; }
.myflaticon-pig-1:before { content: "\f13d"; }
.myflaticon-sheep:before { content: "\f13e"; }
.myflaticon-fish:before { content: "\f13f"; }
.myflaticon-meat:before { content: "\f140"; }
.myflaticon-fruit:before { content: "\f141"; }
.myflaticon-fruit-1:before { content: "\f142"; }
.myflaticon-wine:before { content: "\f143"; }
.myflaticon-summer:before { content: "\f144"; }
.myflaticon-summer-1:before { content: "\f145"; }
.myflaticon-food:before { content: "\f146"; }
.myflaticon-bread:before { content: "\f147"; }
.myflaticon-grape:before { content: "\f148"; }

$font-myflaticon-beds_single: "\f100";
$font-myflaticon-parkings: "\f101";
$font-myflaticon-sports-and-competition: "\f102";
$font-myflaticon-food-and-restaurant: "\f103";
$font-myflaticon-beds_full: "\f104";
$font-myflaticon-beds_bunk: "\f105";
$font-myflaticon-business-and-finance: "\f106";
$font-myflaticon-kid-and-baby: "\f107";
$font-myflaticon-furniture-and-household-1: "\f108";
$font-myflaticon-furniture-and-household-2: "\f109";
$font-myflaticon-car-rental: "\f10a";
$font-myflaticon-views: "\f10b";
$font-myflaticon-tv: "\f10c";
$font-myflaticon-spa: "\f10d";
$font-myflaticon-wifi: "\f10e";
$font-myflaticon-balcony: "\f10f";
$font-myflaticon-facade: "\f110";
$font-myflaticon-animal-kingdom: "\f111";
$font-myflaticon-branch: "\f112";
$font-myflaticon-agriculture: "\f113";
$font-myflaticon-animal: "\f114";
$font-myflaticon-animals: "\f115";
$font-myflaticon-animal-1: "\f116";
$font-myflaticon-animal-kingdom-1: "\f117";
$font-myflaticon-animals-1: "\f118";
$font-myflaticon-bakery: "\f119";
$font-myflaticon-apiary: "\f11a";
$font-myflaticon-bottle: "\f11b";
$font-myflaticon-animal-2: "\f11c";
$font-myflaticon-cheese: "\f11d";
$font-myflaticon-animal-kingdom-2: "\f11e";
$font-myflaticon-branch-1: "\f11f";
$font-myflaticon-animal-kingdom-3: "\f120";
$font-myflaticon-animals-2: "\f121";
$font-myflaticon-animal-3: "\f122";
$font-myflaticon-animal-4: "\f123";
$font-myflaticon-apiary-1: "\f124";
$font-myflaticon-bakery-1: "\f125";
$font-myflaticon-apple: "\f126";
$font-myflaticon-agriculture-1: "\f127";
$font-myflaticon-animals-3: "\f128";
$font-myflaticon-bottle-1: "\f129";
$font-myflaticon-cheese-1: "\f12a";
$font-myflaticon-animal-5: "\f12b";
$font-myflaticon-pig: "\f12c";
$font-myflaticon-cow: "\f12d";
$font-myflaticon-chicken: "\f12e";
$font-myflaticon-honeycomb: "\f12f";
$font-myflaticon-cheese-2: "\f130";
$font-myflaticon-eggs: "\f131";
$font-myflaticon-strawberry: "\f132";
$font-myflaticon-apple-1: "\f133";
$font-myflaticon-sunflower: "\f134";
$font-myflaticon-flour: "\f135";
$font-myflaticon-carrot: "\f136";
$font-myflaticon-tractor: "\f137";
$font-myflaticon-grapes: "\f138";
$font-myflaticon-milk: "\f139";
$font-myflaticon-cat: "\f13a";
$font-myflaticon-ostrich-facing-left: "\f13b";
$font-myflaticon-alpaca: "\f13c";
$font-myflaticon-pig-1: "\f13d";
$font-myflaticon-sheep: "\f13e";
$font-myflaticon-fish: "\f13f";
$font-myflaticon-meat: "\f140";
$font-myflaticon-fruit: "\f141";
$font-myflaticon-fruit-1: "\f142";
$font-myflaticon-wine: "\f143";
$font-myflaticon-summer: "\f144";
$font-myflaticon-summer-1: "\f145";
$font-myflaticon-food: "\f146";
$font-myflaticon-bread: "\f147";
$font-myflaticon-grape: "\f148";