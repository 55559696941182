.form-wrap {
  margin-top: -25px;
}

.form-control {
  &:disabled {
    background-color: #eee;

    /* BEGIN: hide input placeholder when field disabled*/
    &::-webkit-input-placeholder { /* WebKit browsers */
      color: transparent;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: transparent;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: transparent;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10+ */
      color: transparent;
    }
    /* END: hide input placeholder when field disabled*/
  }
}

.fv-plugins-message-container {
  .fv-help-block {
    color: #dc3545;
    font-size: 80%;
    margin-top: 0.25rem;
  }
}
