footer.footer {
  #slick-footer {
    //   overflow: hidden;
    .slick-list {
      overflow: hidden;
    }

    @include media-breakpoint-up(md) {
      justify-content: space-between;
    }

    #footer-contact {
      @include media-breakpoint-up(md) {
        order: 5;
      }
    }
  }

  .js-footer-slide {
    @include media-breakpoint-up(md) {
      &--contact {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }

  .list-unstyled {
    li {
      margin-bottom: 0.25rem !important;
    }
  }

  .hmenu-bottom {
    a {
      text-align: center;
    }
  }

}