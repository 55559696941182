html {
  font-size: 16px;

  &.responsive-fontsize {
    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
  }
}

body {
  overflow-x: hidden;
  padding-top: 86px;

  @include media-breakpoint-up(lg) {
    padding-top: 108px;
  }

  @include media-breakpoint-down(md) {
    background-color: $white;
  }
}

.font {
  &-weight {
    &-medium {
      font-family: $font-family-medium;
    }
    &-semi-bold {
      font-family: $font-family-book;
    }
    &-bold {
      font-family: $font-family-bold;
    }
    &-extra-bold {
      font-family: $font-family-extra-bold;
    }
  }

  &-family {
    &-base {
      font-family: "Atten New Regular", sans-serif;
    }
    &-medium {
      font-family: "Atten New Medium", sans-serif;
    }
    &-book {
      font-family: "Atten New Book", sans-serif;
    }
    &-bold {
      font-family: "Atten New Bold", sans-serif;
    }
    &-extra-bold {
      font-family: "Atten New ExtraBold", sans-serif;
    }
  }

  &-size {
    &-xs {
      font-size: $font-size-xs;
    }

    &-sm {
      font-size: $font-size-sm;
    }

    &-base {
      font-size: $font-size-base !important;
    }
  }
}

ul {
  &.list-primary {
    list-style: none;
    padding-left: 0;

    >li {
      line-height: 1.7;
      position: relative;
      padding-left: 1.3em;  /* space to preserve indentation on wrap */
      &:before {
        content: '✓  ';
        position: absolute;
        left: 0;  /* place the SVG at the start of the padding */
        color: $primary;
      }
    }
  }
  &.list-secondary {
    list-style: none;
    padding-left: 0;

    >li {
      line-height: 1.7;
      position: relative;
      padding-left: 1.3em;  /* space to preserve indentation on wrap */
      &:before {
        content: '✓  ';
        position: absolute;
        left: 0;  /* place the SVG at the start of the padding */
        color: $secondary;
      }
    }
  }
}

