.geocoder {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.mapboxgl-ctrl-geocoder {
  background-color: transparent;
  width: 100%;
  max-width: 530px;
  padding-right: 0;
  margin-right: 0;

  &--icon-search,
  &--icon-loading {
    display: none;
  }
  &--icon-close {
    width: 16px;
    height: 16px;
    margin: 14px 7px 8px 5px;
  }

  &--input {
    padding: 18px 1.5rem 18px 1.5rem;
    border: solid 1px $white;
    border-radius: $border-radius-pill-lg 0 0 $border-radius-pill-lg;
    font-size: 0.9rem;
    line-height: 1;
    width: 100%;
    height: 58px;
    background-color: white;
    overflow: visible;
  }
}

/* Suggestions */
.mapboxgl-ctrl-geocoder .suggestions {
  position: absolute;
  left: 0;
  top: 110%; /* fallback */
  top: calc(100% + 6px);
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-radius: $border-radius;
  list-style: none;
  box-shadow: $box-shadow-sm;
  overflow: hidden;
  z-index: 1000;
}

.mapboxgl-ctrl-bottom-left .suggestions,
.mapboxgl-ctrl-bottom-right .suggestions {
  top: auto;
  bottom: 100%;
}

.mapboxgl-ctrl-geocoder .suggestions > li > a {
  cursor: default;
  display: block;
  padding: 6px 12px;
  color: #404040;
}

.mapboxgl-ctrl-geocoder .suggestions > .active > a,
.mapboxgl-ctrl-geocoder .suggestions > li > a:hover {
  color: $body-color;
  background-color: $light;
  text-decoration: none;
  cursor: pointer;
}

.mapboxgl-ctrl-geocoder--suggestion-title {
  font-weight: bold;
  font-family: $font-family-bold;
}

.mapboxgl-ctrl-geocoder--suggestion-title,
.mapboxgl-ctrl-geocoder--suggestion-address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mapboxgl {
  &-map {
    min-height: 250px;
    @include media-breakpoint-down(md) {
      //margin-left: calc(50% - 50vw) !important;
      //margin-right:calc(50% - 50vw) !important;
    }
  }
}

.leaflet {
  &-control {
    &-ocd-search-form {
      input {
        font-family: "Atten New Regular", sans-serif;
        font-weight: normal;
        font-size: 1rem !important;
      }
    }
    // hide the search magnifier glass icon since we use the expanded input field
    // this icon in leaflet functions as open handler for the collapsed input field
    // since we use expanded field, a click on this icon does nothing but confuces the user, who thinks
    // a click on that icon starts the searchin process (ENTER hit is needed for that by default)
    &-ocd-search-icon {
      display: none;
    }
  }

  &-marker-pane {
    .extra-marker {
      &-review {
        opacity: 0.5 !important;
      }
    }
  }

}

.zero-section-map {
  position: relative;
  left: 50%;
  //bottom: 0;
  transform: translate(-50%, -135px);
  max-width: 960px;
  width: 83%;
  height: 170px;
  z-index: 100;

  @include media-breakpoint-down(md) {
    width: 90%;
    height: 180px;
    left: 50%;
    transform: translate(-50%, -245px);
  }

  @include media-breakpoint-down(xs) {
    height: 90px;
  }

  @include media-breakpoint-up(sm) {

  }
  @media (min-width: 992px) {

  }
  @include media-breakpoint-up(lg) {

  }
  &--listig {
    height: 250px;

    z-index: 1000;
    @include media-breakpoint-up(sm) {

    }
    @include media-breakpoint-up(lg) {

    }
    @include media-breakpoint-down(md) {
      height: 250px;
    }
  }
  .mapboxgl {
    &-map {
      z-index: 101;
    }
  }
  &--search {
    z-index: 102;
    position: relative;
    width: 100%;
    top: 30px;

    @include media-breakpoint-up(md) {
      width: 60%;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .input-group-append {
      .btn.btn-lg {
        padding-left: 20px;
        padding-right: 16px;
        i {
          font-size: 1rem;
          padding: 0;
          &::before {
            padding-bottom: 2px;
          }
        }
      }
      @include media-breakpoint-down(md) {
        span {
          display: none;
        }
      }

    }

  }

  &--listing-filters-wrap {
    @extend .d-lg-flex;
    @extend .justify-content-between;

    position: relative;
    width: 100%;
    top: 30px;
    z-index: 1001;

    @include media-breakpoint-up(sm) {

    }
    @include media-breakpoint-up(lg) {

    }

    .nav-pills {
      line-height: 1;
      background-color: #fff;
      border-radius: 27px;
      // overflow: hidden;
      &--general-filters {
        @include media-breakpoint-down(md) {
          flex-direction: column;
          height: auto;
        }
      }
      &--distance {

      }
    }

    .nav-link {
      border-radius: 0;
      color: #222;
    }

    .nav-link.active {
      background-color: #fafafa;
      color: #fd521e;
    }
  }

}

.section-listing-filters {
  flex: 6 1 auto;
  padding: 0 !important;

  @include media-breakpoint-down(md) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @include media-breakpoint-up(lg) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .nav-link {
    padding: 1.2rem 3rem;
  }

  .nav-item {
    border-bottom: solid 1px #e8e8e8;
    margin-bottom: -1px;
  }

  @include media-breakpoint-up(sm) {
    .nav-item:not(:first-child) {
      border-left: solid 1px #e8e8e8;
    }
  }
  @include media-breakpoint-between(sm, xl) {
    .nav-item {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .nav-item:nth-child(3) {
      border-left: none;
    }
  }

  &-distance {
    flex: 4 1 auto;
    display: flex;

    @include media-breakpoint-up(lg) {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    &.nav.nav-pills {
      padding: 0;

      .nav-item {
        flex: 1 1 auto;

        &:not(:first-child) {
          border-left: solid 1px #e8e8e8;
        }

        &:nth-child(1) {
          .nav-link {
            border-top-left-radius: 27px;
          }
        }
        &:nth-child(2) {
          .nav-link {
            border-top-right-radius: 27px;
          }
        }
        &:nth-child(3) {
          .nav-link {
            border-bottom-left-radius: 27px;
          }
        }
        &:nth-child(4) {
          .nav-link {
            border-bottom-right-radius: 27px;
          }
        }

        .nav-link {
          padding: 1.2rem 1rem;
          text-align: center;
          justify-content: center;
        }
      }

      @include media-breakpoint-down(lg) {
        .nav-item {
          -ms-flex: 0 0 50%;
          flex: 0 0 50%;
          max-width: 50%;
          margin-bottom: -1px;
          margin-right: 0;
          &:nth-child(1),
          &:nth-child(2) {
            border-bottom: solid 1px #e8e8e8;
          }
          &:nth-child(1),
          &:nth-child(3) {
            border-left: none;
          }

          .nav-link {
            background-color: transparent;
          }
          .nav-link.active {
            background-color: #fd521e;
            color: #fff;
          }

        }
      }
    }
  }
}