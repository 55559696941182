.log-in-wrap {
    @extend .position-relative;
    // @extend .w-lg-80;
    @extend .rounded-lg-display-lg;
    @extend .overflow-hidden;
    @extend .bg-white;
    @extend .shadow-sm-display-lg;
    @extend .z-index-2;
    // @extend .mx-n2;
    @extend .mx-lg-auto;

    width: calc( 10/12 * 100% );
    @include media-breakpoint-down(md) {
        width: calc(100% + (2 * 8px));
        margin: 0 -8px;
    }

    &-mobile {
        width: calc( 10/12 * 100% );
        @include media-breakpoint-down(md) {
            width: calc(100% + (2 * 8px));
            margin: 0 -8px;
        }
    }
}
