@import 'zamiastowi-html/assets/scss/front/_variables.scss';
@import 'zamiastowi-html/assets/scss/_user-variables.scss';

.rating {
    font-size:12px;

    &-well {
        display: inline-block;
        direction: rtl;
    }

    input.rating {
        display: none;
    }

    div.star {
        font-family:'Font Awesome 5 Free';
        font-weight:normal;
        font-style:normal;
        font-size: 16px;
        display:inline-block;
        position: relative;

        &:hover {
            cursor:pointer;
        }

        &:before {
            content:"\f005";
            padding-right:5px;
            color: $primary;
            opacity: 0.7;
        }

        &:hover:before, &:hover~div.star:before {
            content:"\f005";
            font-weight:900;
            color:#FFEC5A;
        }
    }

    div.star-full {
        font-family:'Font Awesome 5 Free';
        font-weight:900;
        font-style:normal;
        font-size: 16px;
        display:inline-block;
        position: relative;
    }

    div.star-full:before  {
        content:"\f005";
        padding-right:5px;
        color:#FFEC5A;
    }

    div.star-empty {
        font-family:'Font Awesome 5 Free';
        font-weight:normal;
        font-style:normal;
        font-size: 16px;
        display:inline-block;
        position: relative;
    }

    div.star-empty:before  {
        content:"\f005";
        padding-right:5px;
        color: $primary;
        opacity: 0.7;
    }

    div.fa-norm {
        font-size: 1em;
    }

    div.fa-lg {
        font-size: 1.33333333em;
        line-height: 0.75em;
        vertical-align: -15%;
    }

    div.fa-2x {
        font-size: 2em;
    }

    div.fa-3x {
        font-size: 3em;
    }

    div.fa-4x {
        font-size: 4em;
    }

    div.fa-5x {
        font-size: 5em;
    }
}

.smile-rating {

    /* HIDE RADIO */
    [type=radio] {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
    }

    .face {
        font-family:'Font Awesome 5 Free';
        font-weight:normal;
        font-style:normal;
        font-size: 35px;
        display:inline-block;
        position: relative;

        &:before  {
            padding-right:5px;
            color: $primary;
        }
        &:hover {
            cursor:pointer;
        }
    }

    &--display {
        .face {
            &:hover {
                cursor:auto;
                position:relative;
            }
        }
        &--count {
            display: inline-block
        }
    }

    &.mini {
        .face {
            font-size: 20px;
        }
    }

    .face.faint {
        &:before  {
            padding-right:5px;
            color:#eee;
        }
    }
    .face-positive {
        &:before  {
            content: "\f118";
        }
        &:hover, &.clicked {
            &:before  {
                color: #1c7430;
            }
        }
    }
    .face-positive.faint {
        &:hover {
            &:before  {
                color: #eee;
            }
        }
    }
    .face-neutral {
        &:before  {
            content: "\f11a";
        }
        &:hover, &.clicked {
            &:before  {
                color: #ffcc00;
            }
        }
    }
    .face-neutral.faint {
        &:hover {
            &:before  {
                color: #eee;
            }
        }
    }
    .face-negative {
        &:before  {
            content: "\f119";
        }
        &:hover, &.clicked {
            &:before  {
                color: #9e362f;
            }
        }
    }
    .face-negative.faint {
        &:hover {
            &:before  {
                color: #eee;
            }
        }
    }

}

.review {
    &__date {
    }
    &__pros {
        i {
            color: #1c7430;
        }
    }
    &__cons {
        i {
            color: #9e362f;
        }
    }
    &__counter-comment {

        padding: 5px 5px 5px 30px;
        margin: 5px 0 5px 0;

        i {
            margin-left: -20px;
            margin-right: 8px;
        }

    }
    .kt-widget3__status {
        .face {
            font-size:25px;
        }
    }
}

.ratings {

    &__handles {
        &.login-required {
            .btn {
                color:gray !important;
                &:hover {
                    color: gray !important;
                    border-color: gray !important;
                }
            }
        }
    }

}